(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('stateHandler', stateHandler);

  stateHandler.$inject = ['$rootScope', '$window', 'Auth', 'VERSION'];

  function stateHandler (
    $rootScope, $window,
    Auth, VERSION
  ) {
    return {
      initialize: initialize,
    };

    function initialize () {
      $rootScope.VERSION = VERSION;

      var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;
        $rootScope.fromState = fromState;

        // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
        if (toState.external) {
          event.preventDefault();
          $window.open(toState.url, '_self');
        }

        // Auth.authorize();
      });

      var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        // var titleKey = 'isearch';

        // Set the page title key to the one configured in state or use default one
        /*         if (toState.data.pageTitle) {
          titleKey = toState.data.pageTitle;
        }
        $window.document.title = titleKey; */
      });

      $rootScope.$on('$destroy', function () {
        if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
          stateChangeStart();
        }
        if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
          stateChangeSuccess();
        }
      });
    }
  }
}());

// (function () {
//     'use strict';
//     angular
//         .module('isearchApp')
//         .config(['$localStorageProvider',
//         function ($localStorageProvider) {
//
//         }]);
// ;
// })();

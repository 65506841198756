/* global d3 */
(function () {
  angular
    .module('isearchApp')
    .component('tagcloud', {
      bindings: {
        /**
        * APIs available:
        * initTagcloud: Initialize the tag cloud
        */
        api: '=',
      },
      template: '',
      controller: tagcloud,
    });

  tagcloud.$inject = [];

  function tagcloud () {
    var $ctrl = this;
    $ctrl.svgElement = 'tagcloud';
    $ctrl.showTagCloud = false;
    $ctrl.filterConfigs = undefined;

    $ctrl.tagCloudWidth = 400;
    $ctrl.tagCloudHeight = 400;
    $ctrl.fontSizeMin = 10;
    $ctrl.fontSizeMax = 15;
    $ctrl.tagCloudSize = 10; //  number of tags to show in cloud
    $ctrl.tagCloudConstant = 550;
    $ctrl.maxRatio = 3.5;

    $ctrl.generateTagcloud = function (wordObject, onClick) {
      // eslint-disable-next-line no-param-reassign
      wordObject = _.slice(wordObject, 0, $ctrl.tagCloudSize); // take only the top few results

      $ctrl.removeTagcloud();

      // exit if not words to render!
      if (wordObject <= 0) {
        return;
      }

      var fill = d3.scale.category20();
      var wordEntries = d3.entries(wordObject);
      var xScale = d3.scale.sqrt()
        .domain([d3.min(wordEntries, function (d) {
          return d.value.docCount;
        }), d3.max(wordEntries, function (d) {
          return d.value.docCount;
        }),
        ])
        .range([$ctrl.fontSizeMin, $ctrl.fontSizeMax]);

      $ctrl.calculateScale = function () {
        var maxLength = _.maxBy(wordObject, function (word) {
          word.uiLength = word.key.length * xScale(+word.docCount);
          return word.uiLength;
        }).uiLength;

        var ratio = Math.min($ctrl.tagCloudConstant / maxLength, $ctrl.maxRatio);
        _.forEach(wordObject, function (word) {
          word.scale = ratio * xScale(+word.docCount);
        });
      };
      $ctrl.calculateScale();

      d3.layout.cloud()
        .size([$ctrl.tagCloudWidth, $ctrl.tagCloudHeight])
        .timeInterval(20)
        .words(wordEntries)
        .fontSize(function (d) {
          return d.value.scale;
        })
        .text(function (d) {
          return d.value.key;
        })
        .rotate(function () {
          return 0; //  set rotation to 0
        })
        // .font('Helvetica')
        .padding(8)
        .on('end', drawCloud)
        .start();

      d3.layout.cloud().stop();

      function drawCloud (words) {
        d3.select($ctrl.svgElement).append('canvas')
          .attr('width', 400)
          .attr('height', 400);

        d3.select($ctrl.svgElement).append('svg')
          .style('width', '100%')
          .attr('preserveAspectRatio', 'xMinYMin meet')
          .attr('viewBox', '0 0 ' + $ctrl.tagCloudWidth + ' ' + $ctrl.tagCloudHeight)
          .append('g')
          //  translate by half (floored) of width and height to move origin from top left to center of div
          .attr('transform', 'translate(' + [
            Math.floor($ctrl.tagCloudWidth / 2), Math.floor($ctrl.tagCloudHeight / 2),
          ] + ')')
          .selectAll('text')
          .data(words)
          .enter()
          .append('text')
          .style('font-size', function (d) {
            return (d.value.scale) + 'px';
          })
          .style('font-family', 'Helvetica')
          .style('cursor', 'pointer')
          // .style("opacity", function(d){ return d.value.docCount / highestCount  })
          .style('fill', function () {
            return fill(1);
          })
          .attr('text-anchor', 'middle')
          .attr('transform', function (d) {
            return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
          })
          .text(function (d) {
            return d.text;
          })
          .on('click', onClick);
      }
    };

    $ctrl.removeTagcloud = function () {
      // Remove all contents of the tagcloud element
      d3.selectAll($ctrl.svgElement + ' > *').remove();
    };

    $ctrl.api = {
      generateTagcloud: $ctrl.generateTagcloud,
    };
  }
}());

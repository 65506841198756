(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('ConceptBrowserModalController', ConceptBrowserModalController);
  ConceptBrowserModalController.$inject = ['$scope', '$uibModalInstance', 'KmService'];

  function ConceptBrowserModalController ($scope, $uibModalInstance, KmService) {
    var vm = this;

    vm.showFilters = false;
    vm.isSearchEmpty = true;
    vm.selectedCharFilters = [];
    vm.results = []; // results are individuals which have been filtered off
    vm.searchTerms = {};
    vm.concepts = {};
    vm.individuals = []; // individuals are the full list of individuals for a concept selected
    vm.individualsMap = {};
    vm.conceptLabel = 'concept';

    $scope.$watch('vm.searchTerms', function (newVal, oldVal) {
      vm.isSearchEmpty = _.keys(vm.searchTerms).length === 0;
      _.forOwn(vm.searchTerms, function (value, key) {
        if (!value) {
          delete vm.searchTerms[key];
        }
      });
    }, true);

    $scope.$watch('vm.selectedClass', function (newVal, oldVal) {
      if (!newVal) {
        return;
      }

      vm.refreshFilters();
    });

    vm.refreshFilters = function () {
      // Clear selected characters
      vm.selectedCharFilters = [];
      // Clear search terms
      vm.searchTerms = {};
      // Clear results, if not will show previous concepts individuals
      vm.results = [];

      // Highlighthing first filter should only happen on first load
      KmService.getTagTerms([vm.selectedClass])
        .then(function (data) {
          vm.individuals = _.orderBy(data[vm.selectedClass],
            function (individual) {
              return _.upperCase(individual);
            }, 'asc');
          vm.individualsMap = _.groupBy(vm.individuals,
            function (individual) {
              return _.upperCase(individual[0]);
            });

          // Show the alphabet filter if the result set is too large
          if (vm.individuals && vm.individuals.length > 10) {
            vm.showFilters = true;
          }
          else {
            vm.showFilters = false;
            vm.results = vm.individuals;
          }
        });
    };

    vm.getFilterChars = function () {
      if (!vm.individualsMap) {
        return [];
      }
      return _.keys(vm.individualsMap).sort();
    };

    vm.isFilterCharSelected = function (char) {
      return vm.selectedCharFilters.indexOf(char) > -1;
    };

    vm.updateResults = function (chosenChar) {
      // Clear results
      vm.results = [];
      if (chosenChar) {
        var index = vm.selectedCharFilters.indexOf(chosenChar);
        if (index > -1) {
          // remove if selected before
          vm.selectedCharFilters.splice(index, 1);
        }
        else {
          // else add to the array
          vm.selectedCharFilters.push(chosenChar);
        }
      }

      // Filter the results based on the selected character
      _.forEach(vm.selectedCharFilters, function (char) {
        vm.results = _.concat(vm.results, vm.individualsMap[char]);
      });
      vm.results.sort();
    };

    vm.clearFilters = function () {
      vm.selectedCharFilters = [];
      vm.searchTerms = {};
      vm.updateResults();
    };

    vm.triggerSearch = function () {
      if (vm.isSearchEmpty || _.isEmpty(vm.selectedClass)) {
        return;
      }

      var searchQuery = _.keys(vm.searchTerms)
        .map(function (t) {
          return vm.selectedClass + ':"' + t + '"';
        })
        .join(' OR ');

      $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', searchQuery);
      vm.cancel(true);
    };

    vm.cancel = function (isSearch) {
      if (isSearch === true) {
        vm.searchTerms = {};
        vm.clearFilters();
      }
      $uibModalInstance.dismiss('cancel');
    };

    getAggregations();

    function getAggregations () {
      KmService.getTagTopics().then(function (data) {
        vm.concepts = data;
      });
    }
  }
}());

(function () {
  angular
    .module('isearchApp')
    .factory('AggregationModel', AggregationFactory);

  function AggregationFactory () {
    function AggregationModel (data) {
      if (angular.isUndefined(data)) {
        return undefined;
      }

      var agg = {};

      agg = {
        name: angular.isDefined(data.name) ? data.name : null,
        docCount: angular.isDefined(data.docCount) ? data.docCount : null,
        bucket: [],
      };

      var mapBuckets = function (bucketData) {
        var buckets = [];

        if (angular.isUndefined(data)) {
          return buckets;
        }

        _.forEach(bucketData, function (eachBucketData) {
          var bucket = {
            key: angular.isDefined(eachBucketData.key) ? eachBucketData.key : null,
            docCount: angular.isDefined(eachBucketData.docCount) ? eachBucketData.docCount : 0,
            subAggregations: angular.isDefined(eachBucketData.subAggregations) ? eachBucketData.subAggregations : null,
            uiChecked: false,
          };
          buckets.push(bucket);
        });

        return buckets;
      };

      agg.bucket = mapBuckets(data.bucket);

      return agg;
    }
    /**
     * Static methods , assigned to class
     * Instance ('this') is not available in static context
     */

    /**
      * Build array of AggregationModel from JSON array response
      */
    AggregationModel.buildFromArray = function (responseData) {
      var aggregations = {};

      if (angular.isUndefined(responseData)) {
        return aggregations;
      }

      aggregations = _(responseData)
        .map(function (data) {
          return new AggregationModel(data);
        })
        .keyBy(function (model) {
          return model.name;
        })
        .value();

      return aggregations;
    };

    /**
     * Preprocessing for data returned from API for filters
     *
     */
    AggregationModel.processFilters = function (data) {
      if (!_.isArray(data)) {
        return [];
      }
      _.forEach(data, function (filterOption) {
        filterOption.uiChecked = false;
      });
      return data;
    };

    return AggregationModel;
  }
}());

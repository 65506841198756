(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchTagcloud', {
      bindings: {
        /**
        * APIs available:
        * initTagcloud: Initialize the tag cloud
        */
        api: '=',
      },
      templateUrl: 'app/components/faceted-search-tagcloud/faceted-search-tagcloud.component.html',
      controller: facetedSearchTagcloud,
    });

  facetedSearchTagcloud.$inject = ['$scope', 'SearchService'];

  function facetedSearchTagcloud ($scope, SearchService) {
    var $ctrl = this;
    $ctrl.svgElement = '#tagcloud';
    $ctrl.showTagCloud = false;
    $ctrl.filterConfigs = undefined;

    $ctrl.initTagcloud = function (data) {
      $ctrl.aggregations = _(data.aggregations)
        .filter(function (agg) {
          //  filter to remove filters that are not displayed
          if (angular.isDefined($ctrl.filterConfigs)) {
            if (angular.isUndefined($ctrl.filterConfigs[agg.name])) {
              return false;
            }
            if (angular.isUndefined($ctrl.filterConfigs[agg.name].showInCloud)) {
              return false;
            }
            if ($ctrl.filterConfigs[agg.name].showInCloud !== true) {
              return false;
            }
          }

          // filter to remove aggregations with no buckets
          if (angular.isUndefined(agg.bucket) || agg.bucket === null || agg.bucket.length <= 0) {
            return false;
          }
          return true;
        })
        .flatMap(function (agg) {
          return _.map(agg.bucket, function (b) {
            b.filterKey = agg.name;
            return b;
          });
        })
        .groupBy('key') //  Group together all aggregattions with the same key
        .map(function (keyList) {
          return _.maxBy(keyList, 'docCount'); //  only take the key with largest doccount
        })
        .orderBy(['docCount', 'key'], ['desc', 'asc']) //  order by highest doc count first, then key ascending
        .value();

      // Render the tagcolud if there are any aggregations
      if ($ctrl.aggregations.length > 0) {
        $ctrl.showTagCloud = true;
      }
      else {
        $ctrl.showTagCloud = false;
      }

      $ctrl.tagcloudApi.generateTagcloud($ctrl.aggregations, function (d) {
        var query = d.value.filterKey + ':"' + d.text + '"';
        $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', query);
      });
    };

    $ctrl.toggleTagCloudView = function () {
      $ctrl.showTagCloud = !$ctrl.showTagCloud;
    };

    /**
     * API to be used by faceted-search
     */
    $ctrl.api = {
      initTagcloud: $ctrl.initTagcloud,
    };

    $ctrl.$onInit = function () {
      if (angular.isUndefined($ctrl.filterConfigs)) {
        //  getFilterConfigs() returns a promise. we will use the data in the promise and assign
        SearchService.getFilterConfigs().then(function (data) {
          $ctrl.filterConfigs = data;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig ($stateProvider) {
    $stateProvider.state('app', {
      abstract: true,
      views: {
        'navbar@': {
          templateUrl: 'app/layouts/navbar/navbar.html',
          controller: 'NavbarController',
          controllerAs: 'vm',
        },
      },
      resolve: {
        authorize: ['Auth',
          function (Auth) {
            return Auth.authorize();
          },
        ],
      },
    });
  }
}());

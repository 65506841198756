(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('SearchService', SearchService);
  SearchService.$inject = ['$http', '$q', 'URL_BASE', 'Auth', 'IS_AUTH_ENABLED', '$location'];

  function SearchService ($http, $q, URL_BASE, Auth, IS_AUTH_ENABLED, $location) {
    var configParams = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: IS_AUTH_ENABLED,
    };
    var apiPath = '/isearch-search';
    SearchService.RESULTS_PER_PAGE = 10;
    SearchService.filterConfigs = undefined;

    // SearchService.RESULTS_PER_PAGE = 10;
    SearchService.SOURCE_TYPES = {
      ALL: 'All',
    };

    //  The following list is referenced from backend - SearchMode.java
    SearchService.SEARCH_MODE = {
      NEW: 'NEW',
      SEARCH_WITHIN: 'SEARCH_WITHIN',
      FILTER: 'FILTER',
      SOURCE: 'SOURCE',
      PAGINATION: 'PAGINATION',
      SORTING: 'SORTING',
      AGGREGATION: 'AGGREGATION',
      SAVE_SEARCH: 'SAVE_SEARCH',
    };

    /**
         *  Set Request Params for Searching
         * @param {String} searchQuery
         * @param {Object} param
         */

    var setRequestParam = function (searchQuery, param) {
      if (angular.isUndefined(searchQuery) || !searchQuery.length) {
        return false;
      }
      var requestParams = {
        id: angular.isDefined(param.id) ? param.id : null,
        boolQuery: angular.isDefined(param.boolQuery) ? param.boolQuery : {},
        rangeQuery: angular.isDefined(param.rangeQuery) ? param.rangeQuery : [],
        topicsQuery: {
          should: angular.isDefined(param.topicsShould) ? param.topicsShould : [],
          must: angular.isDefined(param.topicsMust) ? param.topicsMust : [],
        },
        query: searchQuery,
        from: angular.isDefined(param.from) ? Number(param.from) : 0,
        size: angular.isDefined(param.size) ? Number(param.size) : SearchService.RESULTS_PER_PAGE,
        searchMode: angular.isDefined(param.searchMode) ? param.searchMode : SearchService.SEARCH_MODE.NEW,
      };

      if (angular.isDefined(param.searchWithin)) {
        requestParams.searchWithin = param.searchWithin;
      }

      if (angular.isDefined(param.source)) {
        if (param.source !== SearchService.SOURCE_TYPES.ALL) {
          requestParams.source = [param.source];
        }
      }

      if (angular.isDefined(param.sortBy)) {
        requestParams.sortBy = param.sortBy;
      }

      return requestParams;
    };

    var parseDateRangeFromCriteria = function (dateObjectList) {
      var getRange = function (range) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(range)) {
          return moment.utc(range).toISOString();
        }
        return Number(range);
      };
      var getUiTitle = function (field) {
        return field === 'lastModifiedDate' ? 'Modified Date' : 'Year';
      };
      var rangeQueryList = [];
      _.forEach(dateObjectList, function (dateObject) {
        if (!angular.isUndefined(dateObject.field)) {
          var ret = {
            field: dateObject.field,
            uiTitle: angular.isDefined(dateObject.uiTitle) ? dateObject.uiTitle : getUiTitle(dateObject.field),
          };

          if (dateObject.from && dateObject.to) {
            ret.from = getRange(dateObject.from);
            ret.to = getRange(dateObject.to);
          }
          else if (dateObject.gte) {
            ret.gte = getRange(dateObject.gte);
          }
          else if (dateObject.lte) {
            ret.lte = getRange(dateObject.lte);
          }
          rangeQueryList.push(ret);
        }
      });
      return rangeQueryList;
    };

    var parseCriteriaRequestParam = function (param) {
      var requestParams = {
        id: angular.isDefined(param.id) ? param.id : null,
        boolQuery: {},
        rangeQuery: angular.isDefined(param.rangeQuery) ? parseDateRangeFromCriteria(param.rangeQuery) : [],
        topicsQuery: {
          should: angular.isDefined(param.topicsQuery.should) ? param.topicsQuery.should : [],
          must: angular.isDefined(param.topicsQuery.must) ? param.topicsQuery.must : [],
        },
        query: param.query,
        sortBy: angular.isDefined(param.sortBy) ? param.sortBy : {},
        from: angular.isDefined(param.from) ? Number(param.from) : 0,
        size: SearchService.RESULTS_PER_PAGE,
        searchMode: SearchService.SEARCH_MODE.SAVE_SEARCH,
      };

      if (angular.isDefined(param.searchWithin)) {
        requestParams.searchWithin = param.searchWithin;
      }

      if (angular.isDefined(param.source) && param.source.length > 0) {
        requestParams.source = param.source;
      }

      return requestParams;
    };

    /**
         * Build filter param with correct structure when using own list
         * from key: [val1, val2]
         * to [
         *  {field: key, value: val1},
         *  {field: key, value: val2}
         * ]
         */
    var buildFilterParam = function (appliedFilters) {
      if (angular.isUndefined(appliedFilters)) {
        return undefined;
      }

      var filterParam = _.flatMap(appliedFilters, function (ele, key) {
        return _.map(ele, function (e) {
          return { field: key, value: e };
        });
      });

      return filterParam;
    };

    /**
         * Build searchWithin param with correct structure from searchWithinText String
         */
    var buildSearchWithin = function (searchWithinList) {
      var searchWithin = [];
      _.forEach(searchWithinList, function (searchWithinText) {
        searchWithin.push({
          value: searchWithinText,
          field: '',
        });
      });
      return searchWithin;
    };

    /**
         * Build Date Range Params from Date Object
         *
         * dateObject = {
         * start: $ctrl.dateData.start,
         * end: $ctrl.dateData.end,
         * field: $ctrl.dateField,
         * uiTitle: $ctrl.dateFilterTitle
         * };
         */
    var buildDateRange = function (dateObject) {
      if (angular.isUndefined(dateObject.field)) {
        return undefined;
      }

      if (!angular.isDefined(dateObject.start) && !angular.isDefined(dateObject.end)) {
        return undefined;
      }

      var ret = {
        field: dateObject.field,
        uiTitle: angular.isDefined(dateObject.uiTitle) ? dateObject.uiTitle : undefined,
      };

      if (dateObject.start && dateObject.end) {
        ret.from = dateObject.start.toISOString();
        ret.to = dateObject.end.toISOString();
      }
      else if (dateObject.start) {
        ret.gte = dateObject.start.toISOString();
      }
      else if (dateObject.end) {
        ret.lte = dateObject.end.toISOString();
      }

      return ret;
    };


    /**
     * Build Year Range Params from Year Object. Returns list of ret
     */
    var buildYearRange = function (yearObject) {
      if (!angular.isDefined(yearObject.start) && !angular.isDefined(yearObject.end)) {
        return undefined;
      }

      var retList = [];

      var ret = {
        field: 'creationPeriod',
        uiTitle: angular.isDefined(yearObject.uiTitle) ? yearObject.uiTitle : undefined,
        format: 'number',
      };

      if (yearObject.start) {
        var retStart = angular.copy(ret);
        retStart.field += '.startYear';
        retStart.gte = Number(yearObject.start).toString();
        retList.push(retStart);
      }
      if (yearObject.end) {
        var retEnd = angular.copy(ret);
        retEnd.field += '.endYear';
        retEnd.lte = Number(yearObject.end).toString();
        retList.push(retEnd);
      }

      return retList;
    };


    /**
         * Get Offset depending on what page of results is requested
         * @param {Integer} curPage
         */
    var getOffsetByPage = function (curPage) {
      return (curPage - 1) * SearchService.RESULTS_PER_PAGE;
    };

    /**
         * Get page user is on depending on offset of query
         */
    var getPageByOffset = function (offset) {
      return Math.floor(offset / SearchService.RESULTS_PER_PAGE) + 1;
    };

    /**
         * Query Aggregation API to get available filter names
         * private api call !!
         */

    SearchService.execGetAggregationsList = function () {
      return $http
        .get(URL_BASE + apiPath + '/aggregations', configParams)
        .then(function (response) {
          return response.data;
        });
    };

    /**
         * populate filterConfigs by calling aggregations api
         * This function returns a promise with filterConfigs object
         */
    var getFilterConfigs = function () {
      //  create defer object first
      var deferred = $q.defer();

      if (angular.isDefined(SearchService.filterConfigs)) {
        //  add filterConfigs object to promise
        deferred.resolve(SearchService.filterConfigs);

        //  return the promise
        return deferred.promise;
      }

      //  if filterConfigs is undefined, call API
      SearchService.execGetAggregationsList()
        .then(function (data) {
          //  TODO: move to Model
          SearchService.filterConfigs = _.mapValues(data, function (opt, key) {
            if (angular.isDefined(opt)) {
              opt.filterKey = key;
            }
            return opt;
          });

          //  add filterConfigs object to promise
          deferred.resolve(SearchService.filterConfigs);
        })
        .catch(function (err) {
          //  catch any errors
          deferred.reject(err);
        });

      //  return the promise
      return deferred.promise;
    };

    var searchService = {
      /**
             *  Query server search API
             * @param {String} searchQuery
             */
      execSimpleSearch: function (searchQuery, param) {
        var request = setRequestParam(searchQuery, param);
        return $http
          .post(URL_BASE + apiPath + '/v3/search', request, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execNLPSearch: function (searchQuery) {
        return $http
          .post(URL_BASE + apiPath + '/naturalLanguageSearch', searchQuery, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execSimpleSearchFromCriteria: function (request) {
        return $http
          .post(URL_BASE + apiPath + '/v3/search', parseCriteriaRequestParam(request), configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execCategories: function (searchQuery, param) {
        param.searchMode = SearchService.SEARCH_MODE.AGGREGATION;
        var request = setRequestParam(searchQuery, param);
        return $http
          .post(URL_BASE + apiPath + '/v3/search-category', request, configParams)
          .then(function (response) {
            return response.data;
          });
      },


      execCategoriesFromCriteria: function (request) {
        return $http
          .post(
            URL_BASE + apiPath + '/v3/search-category',
            _.omit(parseCriteriaRequestParam(request), 'source'), configParams
          )
          .then(function (response) {
            return response.data;
          });
      },

      /**
             *  Query "You may also want to try..." API.
             * @param {String} searchQuery
             */
      execSuggestion: function (searchQuery) {
        //  term = documentFactory.deleteHashtag(term);
        return $http
          .get(URL_BASE + apiPath + '/youmayalsowanttotry?searchText=' + encodeURIComponent(searchQuery), configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /**
             *  Query "Did you mean..." API.
             * @param {String} searchQuery
             */
      execCorrection: function (searchQuery) {
        return $http
          .get(URL_BASE + apiPath + '/didyoumean?searchText=' + encodeURIComponent(searchQuery), configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /**
             * Query Aggregation API for All Aggregations
             */
      execGetAllAggregation: function (searchQuery, type, requestParam) {
        var request = setRequestParam(searchQuery, requestParam || {});
        request.searchMode = SearchService.SEARCH_MODE.AGGREGATION;
        var paramsString = '?level=1';
        if (type) {
          paramsString += '&type=' + type;
        }
        return $http
          .post(URL_BASE + apiPath + '/v3/search/aggs' + paramsString, request, configParams)
          .then(function (response) {
            return response.data;
          });
      },
      /**
             * Query Aggregation API to get the filter classes to be aggregated on
             * @param {String} searchQuery
             */
      execGetAggregationClasses: function (searchQuery) {
        var request = setRequestParam(searchQuery, {});

        return $http.post(URL_BASE + apiPath + '/v3/search/aggs/level1', request, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execGetAggregationByClass: function (searchQuery, concept, filterParams) {
        const param = {};
        if (filterParams) {
          param.topicsMust = filterParams;
        }

        var request = setRequestParam(searchQuery, param);
        request.aggsParam = {
          concept: concept,
        };

        return $http.post(URL_BASE + apiPath + '/v3/search/aggs/level2', request, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /**
             * Query Aggregation API by filterName (like docFormat)
             */
      execGetAggregation: function (searchQuery, filterName, type) {
        var request = setRequestParam(searchQuery, {});
        request.searchMode = SearchService.SEARCH_MODE.AGGREGATION;
        var paramsString = '?level=1';
        if (type) {
          paramsString += '&type=' + type;
        }
        return $http
          .post(URL_BASE + apiPath + '/v3/search/aggs/' + filterName + paramsString, request, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execGetAutocomplete: function (textToComplete, type) {
        var paramsString = '?searchText=' + textToComplete;
        if (type) {
          paramsString += '&type=' + type;
        }
        return $http
          .get(URL_BASE + apiPath + '/autocomplete' + paramsString, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /*
                  * Build autocomplete list from map of autocomplete source to list of terms
                  */
      buildAutocomplete: function (data) {
        var mergedAutocompletes = data.autocompleteSuggest.slice(0, 5);
        if (data.autocompleteMetrics) {
          mergedAutocompletes = data.autocompleteMetrics.slice(0, 5).concat(mergedAutocompletes);
        }
        var allAutocompleteItem = _.map(mergedAutocompletes, function (item) {
          delete item.score;
          return item;
        });
        /* var uniqueAutocompleteText = allAutocompleteText.filter(function (value, index, self) {
                          return self.indexOf(value) === index;
                        }); */
        return allAutocompleteItem;
      },

      execSaveSearch: function (searchQuery, param, searchName) {
        var request = setRequestParam(searchQuery, param);
        var payload = {
          name: searchName,
          request: request,
        };
        return $http
          .post(URL_BASE + apiPath + '/v3/criteria', payload, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execGetSavedSearches: function () {
        var userModel = Auth.getUser();

        if (userModel === null) {
          return null;
        }

        var userId = userModel.id;

        return $http
          .get(URL_BASE + apiPath + '/v3/criteria/user/' + userId, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execRenameSavedSearch: function (newSearchName, requestObject) {
        var searchId = angular.isDefined(requestObject.id) ? requestObject.id : '';
        var criteria = {
          name: newSearchName,
          request: requestObject,
        };
        return $http
          .put(URL_BASE + apiPath + '/v3/criteria/' + searchId, criteria, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execDeleteSavedSearch: function (requestObject) {
        var searchId = angular.isDefined(requestObject.id) ? requestObject.id : '';
        return $http
          .delete(URL_BASE + apiPath + '/v3/criteria/' + searchId, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execGetCriteria: function (criteriaID) {
        return $http
          .get(URL_BASE + apiPath + '/v3/criteria/' + criteriaID, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execShareSavedSearch: function (criteriaID, email) {
        return $http
          .post(URL_BASE + apiPath + '/v3/share/' + criteriaID,
            {
              user: Auth.getUser().firstName,
              to: email,
              baseUrl: $location.protocol()
                  + '://'
                  + $location.host()
                  + ':' + $location.port()
                  + '/#'
                  + '/criteria?criteria='
                  + criteriaID,
            }, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      execGetTextContent: function (id) {
        var userModel = Auth.getUser();

        var contentRequest = {
          id: id,
          user: userModel,
        };

        return $http
          .post(URL_BASE + apiPath + '/v3/textContent', contentRequest, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /**
             * Build Date Range Params from Date Object
             */
      buildDateRange: buildDateRange,
      buildYearRange: buildYearRange,

      /**
             * Build filter param with correct structure when using own list
             */
      buildFilterParam: buildFilterParam,

      /**
             * Build searchWithin param with correct structure from searchWithinText String
             */
      buildSearchWithin: buildSearchWithin,

      parseCriteriaRequestParam: parseCriteriaRequestParam,

      parseDateRangeFromCriteria: parseDateRangeFromCriteria,

      getOffsetByPage: getOffsetByPage,

      getPageByOffset: getPageByOffset,

      getFilterConfigs: getFilterConfigs,

      RESULTS_PER_PAGE: SearchService.RESULTS_PER_PAGE,

      SOURCE_TYPES: SearchService.SOURCE_TYPES,

      SEARCH_MODE: SearchService.SEARCH_MODE,
    };

    return searchService;
  }
}());

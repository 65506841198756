(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('csrfInterceptor', csrfInterceptor);

  csrfInterceptor.$inject = ['$q', '$cookies', 'ConfigService'];

  function csrfInterceptor ($q, $cookies, ConfigService) {
    const secureCookiesEnabled = ConfigService.getSearchConfig('secureCookiesEnabled') || false;

    var service = {
      response: readCSRF,
    };

    return service;

    function readCSRF (response) {
      var headerCSRF = response.headers('SET-CSRF-TOKEN');
      if (headerCSRF === null) {
        return response;
      }
      if (headerCSRF !== null) {
        $cookies.put('CSRF-TOKEN-UI', headerCSRF, { secure: secureCookiesEnabled });
      }

      return response;
    }
  }
}());

// original stackoverflow post https://stackoverflow.com/a/38077388
// working plunker for ui-select v0.1.9.5 http://plnkr.co/edit/C26C8l?p=preview

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .directive('uiSelectChoices', UiSelectChoices);

  UiSelectChoices.$inject = ['$timeout', '$parse', '$filter'];
  function UiSelectChoices ($timeout, $parse, $filter) {
    return function (scope, elm, attr) {
      var raw = elm[0];
      var scrollCompleted = true;
      if (!attr.allChoices) {
        throw new Error('ief:ui-select: Attribute all-choices is required in  ui-select-choices so that we can handle  pagination.');
      }

      scope.pagingOptions = {
        allOptions: scope.$eval(attr.allChoices),
      };

      attr.refresh = 'addMoreItems()';
      var refreshCallBack = $parse(attr.refresh);
      elm.bind('scroll', function (event) {
        var remainingHeight = raw.offsetHeight - raw.scrollHeight;
        var scrollTop = raw.scrollTop;
        var percent = Math.abs((scrollTop / remainingHeight) * 100);

        if (percent >= 80) {
          if (scrollCompleted) {
            scrollCompleted = false;
            event.preventDefault();
            event.stopPropagation();
            var callback = function () {
              scope.addingMore = true;
              refreshCallBack(scope, {
                $event: event,
              });
              scrollCompleted = true;
            };
            $timeout(callback, 100);
          }
        }
      });

      var closeDestroyer = scope.$on('uis:close', function () {
        var pagingOptions = scope.$select.pagingOptions || {};
        pagingOptions.filteredItems = undefined;
        pagingOptions.page = 0;
      });

      scope.$select.getPagedItems = function () {
        return scope.$select.pagingOptions ? scope.$select.pagingOptions.items : [];
      };

      scope.addMoreItems = function (doneCalBack) {
        // console.log('new addMoreItems');
        var $select = scope.$select;
        var allItems = scope.pagingOptions.allOptions;
        var moreItems = [];
        // var itemThreshold = 40;
        var search = $select.search;

        $select.pagingOptions = $select.pagingOptions || {
          page: 0,
          pageSize: 20,
          items: $select.items,
        };

        var pagingOptions = $select.pagingOptions;

        if (pagingOptions.page === 0) {
          pagingOptions.items.length = 0;
        }
        if (!pagingOptions.originalAllItems) {
          pagingOptions.originalAllItems = scope.pagingOptions.allOptions;
        }

        // console.log('search term=' + search);
        // console.log('prev search term=' + pagingOptions.prevSearch);

        var searchDidNotChange = search && pagingOptions.prevSearch && search === pagingOptions.prevSearch;

        // console.log('isSearchChanged=' + searchDidNotChange);

        if (pagingOptions.filteredItems && searchDidNotChange) {
          allItems = pagingOptions.filteredItems;
        }
        pagingOptions.prevSearch = search;

        if (search && search.length > 0 && !searchDidNotChange) {
          // trigger a new search as search string has changed

          pagingOptions.filteredItems = undefined;
          moreItems = $filter('filter')(pagingOptions.originalAllItems, search);

          pagingOptions.page = 0;
          pagingOptions.items.length = 0;
          pagingOptions.filteredItems = moreItems;
          allItems = pagingOptions.filteredItems;
        }
        pagingOptions.page += 1;
        if (pagingOptions.items.length < allItems.length) {
          moreItems = allItems.slice(pagingOptions.items.length, pagingOptions.page * pagingOptions.pageSize);
        }

        for (var k = 0; k < moreItems.length; k += 1) {
          pagingOptions.items.push(moreItems[k]);
        }
        // console.log($select.pagingOptions, $select.pagingOptions.items.length);

        scope.calculateDropdownPos();
        scope.$broadcast('uis:refresh');
        if (doneCalBack) doneCalBack();
      };
      scope.$on('$destroy', function () {
        elm.off('scroll');
        closeDestroyer();
      });
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .run(run);

  run.$inject = ['SearchService', 'KmService'];

  function run (SearchService, KmService) {
    //  run this function once to populate filterConfigs object within SearchService
    //  this is done before init of components
    //  this is to avoid double API calls when component init
    SearchService.getFilterConfigs();
    KmService.getConcepts();
  }
}());

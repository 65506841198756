(function () {
  angular
    .module('isearchApp')
    .component('searchSuggest', {
      bindings: {
        api: '=',
        onTriggerSuggestedSearch: '&',
      },
      templateUrl: 'app/components/search-suggest/search-suggest.component.html',
      controller: searchSuggest,
    });

  searchSuggest.$inject = ['SearchService', 'ConfigService'];

  function searchSuggest (SearchService, ConfigService) {
    var $ctrl = this;
    $ctrl.quoteSelectedQuery = ConfigService.getSearchConfig('quoteQueryOnSelect');

    $ctrl.suggest = [];
    $ctrl.showSuggestions = false;

    $ctrl.execGetSuggestion = function (query) {
      if (!query.length) {
        return;
      }

      SearchService.execSuggestion(query).then(function (data) {
        $ctrl.suggest = [];

        if (angular.isUndefined(data)) {
          return;
        }

        _.forEach(data, function (suggestData) {
          if (angular.isDefined(suggestData.suggest)) {
            $ctrl.suggest.push(suggestData.suggest);
          }
        });
      });
    };

    $ctrl.triggerSuggestSearch = function (query) {
      var myQuery = query;
      if ($ctrl.quoteSelectedQuery) {
        myQuery = '"' + myQuery + '"';
      }
      if (angular.isFunction($ctrl.onTriggerSuggestedSearch)) {
        $ctrl.onTriggerSuggestedSearch({
          query: myQuery,
        });
      }
    };

    $ctrl.toggleSuggestionView = function () {
      $ctrl.showSuggestions = !$ctrl.showSuggestions;
    };

    $ctrl.api = {
      execGetSuggestion: $ctrl.execGetSuggestion,
    };
  }
}());

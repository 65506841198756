(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchFilterOption', {
      bindings: {
        api: '=',
        configData: '=configData', //  {title: , filterKey:}
        onOptionChange: '&', //  Trigger searchFilter component to update its selected list
        onLoadFilterOptions: '&', //  Trigger searchFilter component to get available filter options
      },
      templateUrl: 'app/components/faceted-search-filter-option/faceted-search-filter-option.component.html',
      controller: facetedSearchFilterOption,
    });

  facetedSearchFilterOption.$inject = [];

  function facetedSearchFilterOption () {
    var $ctrl = this;
    $ctrl.agg = {}; //  AggregationModel
    $ctrl.uiShowContents = false;
    $ctrl.isSearched = true;
    // $ctrl.filterOptionDisplay = {};
    $ctrl.allFilterOptions = [];
    $ctrl.filterOptions = [];
    $ctrl.selectedOptions = [];

    $ctrl.offset = 0;

    /**
     * Toggle display of dropdown and query server for aggregation data
     */
    $ctrl.toggleContent = function () {
      $ctrl.uiShowContents = !$ctrl.uiShowContents;

      if (!$ctrl.uiShowContents) {
        return;
      }

      // contents shown. do not load any more content if exists
      if ($ctrl.filterOptions.length > 0) {
        return;
      }

      if (!angular.isFunction($ctrl.onLoadFilterOptions)) {
        return;
      }
      $ctrl.onLoadFilterOptions({
        conceptList: [$ctrl.configData.filterKey],
        selectedFilters: $ctrl.getSelectedFilters(),
      });
    };

    $ctrl.setFilterData = function (data) {
      $ctrl.filterOptions.length = 0;// Clear contents
      $ctrl.filterOptions.push.apply($ctrl.filterOptions, data);
    };

    // if select already exists and need to update list, use this
    $ctrl.updateFilterData = function (newFilterOptions) {
      const selectedOptions = angular.copy($ctrl.getSelectedFilters());

      $ctrl.setFilterData(newFilterOptions);
      $ctrl.setSelectedFilters(selectedOptions);
    };

    /**
     *  Triggered when user changes option input
     */
    $ctrl.triggerOptionChange = function (bIsReset) {
      var selectedList = $ctrl.getSelectedFilters();
      if (angular.isFunction($ctrl.onOptionChange)) {
        $ctrl.onOptionChange({
          name: $ctrl.configData.filterKey,
          selectedList: selectedList, //  pass selected list
          bIsReset: bIsReset,
        });
      }
    };

    /**
     * Determine whether filter should be displayed
     * It should be defined as Displayed, and have a list of aggregation buckets
     */
    $ctrl.isFilterDisplayed = function () {
      if (angular.isUndefined($ctrl.configData)) {
        return false;
      }

      if (angular.isUndefined($ctrl.configData.displayed)) {
        return false;
      }

      return true;
    };

    /**
     * From AggregationModel, find selected bucket values.
     * Return this Array and
     */
    $ctrl.getSelectedFilters = function () {
      if (angular.isUndefined($ctrl.selectedOptions)) {
        return [];
      }

      return _.map($ctrl.selectedOptions, 'key');
    };

    /**
     * set filter list of this type
     * @param {Array} selectedList
     */
    $ctrl.setSelectedFilters = function (selectedList) {
      $ctrl.selectedOptions = _.filter($ctrl.filterOptions, function (opt) {
        return selectedList.indexOf(opt.key) > -1;
      });
    };

    /**
     * Set uiChecked value to false for Option with specified key
     * @param {String} key  ie. Excel
     */
    $ctrl.uncheckOption = function (key) {
      if (angular.isUndefined($ctrl.selectedOptions)) {
        return;
      }

      _.remove($ctrl.selectedOptions, function (opt) {
        return opt.key === key;
      });
    };

    /**
     * Set uiChecked value to false for all options
     */
    $ctrl.resetFilterOptions = function () {
      $ctrl.filterOptions = [];
      $ctrl.selectedOptions = [];
    };

    $ctrl.hideContent = function () {
      $ctrl.uiShowContents = false;
    };

    $ctrl.showContent = function () {
      $ctrl.uiShowContents = true;
    };

    /**
     *  check if search string is found
     * @param {String} searchString
     */

    $ctrl.api = {
      uncheckOption: $ctrl.uncheckOption,
      triggerOptionChange: $ctrl.triggerOptionChange,
      hideContent: $ctrl.hideContent,
      setSearchQuery: $ctrl.setSearchQuery,
      resetFilterOptions: $ctrl.resetFilterOptions,
      isFilterDisplayed: $ctrl.isFilterDisplayed,
      setSelectedFilters: $ctrl.setSelectedFilters,
      setFilterData: $ctrl.setFilterData,
      updateFilterData: $ctrl.updateFilterData,
    };
  }
}());

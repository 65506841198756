(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchResultsTagsList', {
      bindings: {
        tags: '=',
        topicTitle: '=',
        isEditMode: '=',
        tagSuggestions: '=',
        removeTopic: '&',
        addTopic: '&',
      },
      templateUrl: 'app/components/faceted-search-results-item/faceted-search-results-tags-list/faceted-search-results-tags-list.component.html',
      controller: facetedSearchResultsTagsList,
    });

  facetedSearchResultsTagsList.$inject = ['$scope'];

  /**
       * Events this component will emit:
       * fsrTagsList:tagSearchTriggered - Query String
       */
  function facetedSearchResultsTagsList ($scope) {
    var $ctrl = this;
    $ctrl.showTags = false;
    $ctrl.isAddNewTag = false;
    $ctrl.newTag = '';

    /**
     *  call new search when clicking tag
     * @param {String} topic
     * @param {String} concept
     */
    $ctrl.triggerTagSearch = function (topic, concept) {
      if (!$ctrl.isEditMode) {
        var query = topic + ':"' + concept + '"';

        //  Caught in FacetedSearch component
        $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', query);
      }
    };

    $ctrl.onAddUnseenTag = function (tagString) {
      // handle event when user tries to add a string that is not in the current list of options
      // for now, we prevent it (return nothing)
    };

    $ctrl.clearContents = function () {
      if ($ctrl.topicTitle === 'topic' || !angular.isFunction($ctrl.removeTopic)) {
        return;
      }

      $ctrl.removeTopic({
        topicTitle: $ctrl.topicTitle,
      });
    };

    $ctrl.clearChildContents = function ($item) {
      if (!angular.isFunction($ctrl.removeTopic)) {
        return;
      }

      $ctrl.removeTopic({
        topicTitle: $item,
      });
    };

    $ctrl.addNewSuggestions = function ($item) {
      if ($ctrl.topicTitle !== 'topic' || !angular.isFunction($ctrl.addTopic)) {
        return;
      }

      $ctrl.addTopic({
        item: $item,
      });
    };
  }
}());

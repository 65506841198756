(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('Auth', Auth);

  Auth.$inject = ['LoginModalService', 'UserService', 'UserADService', '$localStorage', 'UserModel', 'IS_AD_AUTH', 'IS_AUTH_ENABLED', '$rootScope', 'ConfigService'];

  function Auth (LoginModalService, UserService, UserADService, $localStorage, UserModel, IS_AD_AUTH, IS_AUTH_ENABLED, $rootScope, ConfigService) {
    var userCallback = null;
    var lastUserADCall = null;
    var sourceAuthorisationEnabled = ConfigService.getSearchConfig('sourceAuthorisationEnabled');

    var service = {
      authorize: authorize,
      login: login,
      logout: logout,
      resetPassword: resetPassword,
      cancel: cancel,
      isAuthenticated: isAuthenticated,
      initUser: initUser,
      updateUserCallback: updateUserCallback,
      getUser: getUser,
      isActiveDirectoryAuthentication: isActiveDirectoryAuthentication,
      execADAuth: execADAuth,
      getUserSources: getUserSources,
      hasSourceAccess: hasSourceAccess,
    };
    $rootScope.$on('isearchApp.refreshADUser', function () {
      var now = new Date();
      if (!lastUserADCall || (now - lastUserADCall > 1000)) {
        lastUserADCall = now;
        execADAuth();
      }
    });

    return service;

    function authorize () {
      if (!$localStorage.userModel) {
        //  open modal
        if (IS_AUTH_ENABLED && !isActiveDirectoryAuthentication()) {
          LoginModalService.open();
        }
      }
    }

    function isAuthenticated () {
      if (!IS_AUTH_ENABLED) {
        return true;
      }
      return angular.isDefined($localStorage.userModel) && $localStorage.userModel != null;
    }

    function getUser () {
      return isAuthenticated() ? $localStorage.userModel : null;
    }

    function login (user, password, rememberMe) {
      UserService.authenticate(user, password, rememberMe)
        .then(function (data) {
          $localStorage.userModel = UserModel(data);
          // UserService.getCsrf();
          triggerUserCallback($localStorage.userModel);
        });
      LoginModalService.close();
    }

    function logout () {
      UserService.logout()
        .then(function () {
          delete $localStorage.userModel;
          // UserService.getCsrf();
          triggerUserCallback($localStorage.userModel);
        });
    }

    function updateUserCallback (callback) {
      userCallback = callback;
    }

    function triggerUserCallback (userModel) {
      if (angular.isFunction(userCallback)) {
        userCallback(userModel);
      }
    }
    function initUser () {
      if (isActiveDirectoryAuthentication()) {
        execADAuth();
      }
      else {
        // authenticate as normal
        if (!isAuthenticated()) {
          return;
        }
        triggerUserCallback($localStorage.userModel);
      }
    }

    function isActiveDirectoryAuthentication () {
      return IS_AD_AUTH;
    }

    function resetPassword () {
      LoginModalService.close();
    }

    function cancel () {
      LoginModalService.close();
    }
    function execADAuth () {
      UserADService.getUserAuth().then(function (response) {
        if (response) {
          // authenticated!
          if (response === null) {
            delete $localStorage.userModel;
          }
          else {
            $localStorage.userModel = UserModel(response);
          }
          triggerUserCallback($localStorage.userModel);
        }
        /**
         * @todo no auth action ?
         */
      });
    }

    function getUserSources () {
      if (!$localStorage.userModel || !$localStorage.userModel.repoSources) {
        return null;
      }

      return $localStorage.userModel.repoSources;
    }

    function hasSourceAccess (source) {
      if (!sourceAuthorisationEnabled) {
        return true;
      }

      const userSources = getUserSources();

      if (!userSources) {
        return false;
      }

      return _.includes(userSources, source);
    }
  }
}());

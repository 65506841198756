(function () {
  angular
    .module('isearchApp')
    .factory('KmService', KmService);

  KmService.$inject = ['$http', 'URL_BASE', '$q', 'IS_AUTH_ENABLED'];

  function KmService ($http, URL_BASE, $q, IS_AUTH_ENABLED) {
    var configParams = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: IS_AUTH_ENABLED,
    };
    var KM_PATH = '/isearch-km';
    var SEARCH_PATH = '/isearch-search';
    KmService.concepts = undefined;

    var execGetConcepts = function () {
      return $http
        .get(URL_BASE + KM_PATH + '/ontology/getConcepts', configParams)
        .then(function (response) {
          return response.data;
        });
    };

    var kmService = {

      getConcepts: function () {
        //  create defer object first
        var deferred = $q.defer();

        if (angular.isDefined(KmService.concepts)) {
        //  add concepts object to promise
          deferred.resolve(KmService.concepts);

          //  return the promise
          return deferred.promise;
        }

        //  if concepts are undefined, call API
        execGetConcepts()
          .then(function (data) {
            KmService.concepts = data;
            //  add concepts object to promise
            deferred.resolve(KmService.concepts);
          })
          .catch(function (err) {
            //  catch any errors
            deferred.reject(err);
          });

        //  return the promise
        return deferred.promise;
      },

      getRelationsById: function (id) {
        return $http
          .get(URL_BASE + KM_PATH + '/ontology/getRelations?id=' + id, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      /**
      * Get the relations for a search term
      */
      getRelations: function (term) {
        var data = {
          term: term,
        };
        return $http
          .post(URL_BASE + KM_PATH + '/getRelations', data, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      getTagTopics: function () {
        return $http
          .get(URL_BASE + SEARCH_PATH + '/tags/class/all', configParams)
          .then(function (response) {
            return response.data;
          });
      },

      getTagTerms: function (topicsId) {
        return $http
          .post(URL_BASE + SEARCH_PATH + '/tags/individual/all', topicsId, configParams)
          .then(function (response) {
            return response.data;
          });
      },

      saveTags: function (document) {
        return $http
          .post(URL_BASE + SEARCH_PATH + '/tags/updateTags', document, configParams)
          .then(function (response) {
            return response.data;
          });
      },
    };
    return kmService;
  }
}());

(function () {
  'use strict';

  // defaults - will be overlapped by env.js
  var config = {
    baseUrl: 'http://localhost:8080',
    ADAuthEndpoint: '/account/api/user/currentuser',
    isAuthEnabled: true,
    isADAuth: false,
    authTimeoutBuffer: 15,
    customExternalLink: null,
    searchSettings: {
      quoteQueryOnSelect: false,
      appliedFiltersEnabled: true,
      tripleSearchBrowserEnabled: true,
      relationshipBrowserEnabled: true,
      conceptBrowserEnabled: true,
      sourceAuthorisationEnabled: false,
    },
    widgetSettings: {
      searchBar: {
        enabled: false,
        props: {
          postMessageOnSearch: 'true',
        },
      },
    },
  };

    // helper fn to merge objects
  var merge = function (defaultObj, overideObj) {
    var result = {};

    Object.keys(defaultObj).forEach(function (key) {
      result[key] = defaultObj[key];
    });

    Object.keys(overideObj).forEach(function (key) {
      if (angular.isDefined(overideObj[key]) && overideObj[key] !== '') {
        result[key] = overideObj[key];
      }
    });

    return result;
  };

    // merge the config, if it is found on the window context
  var envConfig = window.__envConfig ? merge(config, window.__envConfig) : config;
  console.log('config', config);
  console.log('window.__envConfig', window.__envConfig);
  console.log('envConfig', envConfig);

  angular
    .module('isearchApp')
    .constant('URL_BASE', envConfig.baseUrl)
    .constant('AD_AUTH_ENDPOINT', envConfig.ADAuthEndpoint)
    .constant('IS_AUTH_ENABLED', envConfig.isAuthEnabled)
    .constant('IS_AD_AUTH', envConfig.isADAuth)
    .constant('AUTH_TIMEOUT_BUFFER', envConfig.authTimeoutBuffer)
    .constant('CUSTOM_EXTERNAL_LINK', envConfig.customExternalLink)
    .constant('WIDGET_SETTINGS', envConfig.widgetSettings)
    .constant('SEARCH_SETTINGS', envConfig.searchSettings);
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('RelationshipViewerModalService', RelationshipViewerModalService);

  RelationshipViewerModalService.$inject = ['$uibModal'];

  function RelationshipViewerModalService ($uibModal) {
    var service = {
      open: open,
      close: close,
    };

    var modalInstance = null;
    var resetModal = function () {
      modalInstance = null;
    };

    return service;

    function close () {
      modalInstance.dismiss('cancel');
    }

    function open (searchTerm, scope) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/relationship-viewer/relationship-viewer.modal.html',
        controller: 'RelationshipViewerModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        scope: scope,
        resolve: {
          searchTerm: function () {
            return searchTerm;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }
  }
}());

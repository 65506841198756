(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchResults', {
      bindings: {
        /**
         * APIs available:
         * update: updates the UI based on the search query
         */
        api: '=',
        appliedFilters: '=', //  applied filters updated from facetedSearch
        appliedSort: '=', // applied sort updated from facetedSearch
        dateRange: '=', //  dateRange param from facetedSearch
        yearRange: '=', //  yearRange param from facetedSearch
        searchWithinList: '=', //  saerchWithinList param from facetedSearch
        onRemoveFilter: '&', //  $ctrl.removeFilter(name,key) from facetedSearch
        onRemoveDateRange: '&', //  $ctrl.removeDateRange(dateKey) from facetedSearch
        onRemoveYearRange: '&', //  $ctrl.removeYearRange(dateKey) from facetedSearch
        onRemoveSearchWithin: '&', //  $ctrl.removeSearchWithin(bIsReset) from facetedSearch
        onPageUpdate: '&', //  $ctrl.triggerResultPageUpdate(curPage) from facetedSearch
        onSourceUpdate: '&', //  $ctrl.triggerSourceUpdate(source) from facetedSearch
        onSortChange: '&', //  $ctrl.triggerSortChange(option) from facetedSearch
      },
      templateUrl: 'app/components/faceted-search-results/faceted-search-results.component.html',
      controller: facetedSearchResults,
    });

  facetedSearchResults.$inject = ['$scope', 'SearchService', 'AggregationModel', 'PreviewTextModalService', 'ConfigService'];

  function facetedSearchResults ($scope, SearchService, AggregationModel, PreviewTextModalService, ConfigService) {
    var $ctrl = this;
    $ctrl.searchQuery = ''; // Query that was searched
    $ctrl.resultsNum = 0; //  Number of results searched
    $ctrl.resultsNumForAll = 0;
    $ctrl.resultModel = {}; //  List of documents searched
    $ctrl.curPage = 1; //  Initialise current active page to page 1
    $ctrl.itemPerPage = SearchService.RESULTS_PER_PAGE;
    $ctrl.filterConfigs = undefined;
    $ctrl.sources = [];
    $ctrl.appliedFiltersEnabled = ConfigService.getSearchConfig('appliedFiltersEnabled');

    $ctrl.sortOptions = [
      { value: null, viewValue: 'Relevance', order: 'desc' }, // null because sending in a query with NO sort (sorted by relevance by default)
      { value: 'lastModifiedDate', viewValue: 'Latest Modified Date', order: 'desc' },
      { value: 'lastModifiedDate', viewValue: 'Earliest Modified Date', order: 'asc' },
      { value: 'title.sort', viewValue: 'Alphabetical Ascending', order: 'asc' },
      { value: 'title.sort', viewValue: 'Alphabetical Descending', order: 'desc' },
    ];
    $ctrl.selectedSortOption = $ctrl.sortOptions[0]; // defaults to relevance

    $ctrl.SOURCE_TYPES = SearchService.SOURCE_TYPES;
    $ctrl.selectedSource = $ctrl.SOURCE_TYPES.ALL;

    /**
     * Watcher for applied sort from faceted search
     */
    $scope.$watch('$ctrl.appliedSort', function (newValue, oldValue) {
      if (newValue.field === oldValue.field && newValue.order === oldValue.order) {
        return;
      }

      if (newValue.field) {
        for (var i = 0; i < $ctrl.sortOptions.length; i += 1) {
          var sortOption = $ctrl.sortOptions[i];
          if (sortOption.value === newValue.field && sortOption.order === newValue.order) {
            $ctrl.selectedSortOption = $ctrl.sortOptions[i];
            break;
          }
        }
      }
      else {
        // if no value, default to relevance options
        $ctrl.selectedSortOption = $ctrl.sortOptions[0];
      }
    });

    /**
     *  Updates Results UI
     * @param {String} searchQuery
     */
    var updateResultsList = function (searchQuery, resultModel) {
      if ($ctrl.searchQuery !== searchQuery) {
        $ctrl.curPage = 1; //  reset to page 1 if search query is different
      }
      $ctrl.searchQuery = searchQuery;

      $ctrl.resultsNum = resultModel.total;
      $ctrl.resultsNumForAll = Math.max($ctrl.resultsNum, $ctrl.resultsNumForAll); //  default set resulsNumForAll to resultsNum
      $ctrl.documents = resultModel.documents;
    };

    var updateSources = function (aggregations, selectedSource) {
      $ctrl.resultsNumForAll = 0;
      $ctrl.selectTab(selectedSource || $ctrl.SOURCE_TYPES.ALL, true); //  always reset tab selection to ALL when sources tabs are updated
      var sources = AggregationModel.buildFromArray(aggregations);
      if (angular.isUndefined(sources.category)) {
        return;
      }
      $ctrl.sources = [];
      if (angular.isDefined(sources.category.bucket) && sources.category.bucket.length > 0) {
        $ctrl.sources = sources.category.bucket;
        $ctrl.resultsNumForAll = _.reduce($ctrl.sources, function (result, obj) {
          if (angular.isUndefined(obj.docCount)) {
            return result;
          }
          return obj.docCount + result;
        }, 0);
      }
    };

    $ctrl.selectTab = function (source, bIsReset) {
      $ctrl.selectedSource = source;

      if (bIsReset === true) {
        return; //  do not update faceted search component again
      }

      if (!angular.isFunction($ctrl.onSourceUpdate)) {
        return;
      }
      $ctrl.onSourceUpdate({
        source: $ctrl.selectedSource,
      });
    };

    $ctrl.setTab = function (source) {
      $ctrl.selectedSource = source;
    };

    $ctrl.getResultsBySources = function (source) {
      $ctrl.selectTab(source);
    };

    $ctrl.isSourceSelected = function (source) {
      return $ctrl.selectedSource === source ? 'active' : '';
    };

    $ctrl.getResultsByPage = function () {
      if ($ctrl.curPage > $ctrl.pageNum || $ctrl.curPage < 1) {
        return;
      }

      if (!angular.isFunction($ctrl.onPageUpdate)) {
        return;
      }
      $ctrl.onPageUpdate({
        curPage: $ctrl.curPage,
      });
    };

    $ctrl.getSortOptions = function () {
      return $ctrl.sortOptions;
    };

    $ctrl.sortChange = function () {
      if (!angular.isFunction($ctrl.onSortChange)) {
        return;
      }
      $ctrl.onSortChange({ option: $ctrl.selectedSortOption });
    };

    $ctrl.resetPage = function () {
      $ctrl.curPage = 1;
    };

    $ctrl.setPage = function (page) {
      $ctrl.curPage = page;
    };

    /**
     * Trigger facetedSearch to remove Filter
     * @param {String} name
     * @param {String} key
     */
    $ctrl.removeFilter = function (name, key) {
      if (!angular.isFunction($ctrl.onRemoveFilter)) {
        return;
      }
      $ctrl.onRemoveFilter({
        name: name,
        key: key,
      });
    };

    /**
     * Trigger facetedSearch to remove dateRangeParam
     * @param {String} dateKey
     */
    $ctrl.removeDateRange = function (dateKey) {
      if (!angular.isFunction($ctrl.onRemoveDateRange)) {
        return;
      }
      $ctrl.onRemoveDateRange({
        dateKey: dateKey,
      });
    };

    /**
     * Trigger facetedSearch to remove yearRangeParam
     * @param {String} yearKey
     */
    $ctrl.removeYearRange = function (yearKey) {
      if (!angular.isFunction($ctrl.onRemoveYearRange)) {
        return;
      }
      $ctrl.onRemoveYearRange({
        yearKey: yearKey,
      });
    };

    /**
     * Trigger facetedSearch to remove search within
     */
    $ctrl.removeSearchWithin = function (searchWithinText) {
      if (!angular.isFunction($ctrl.onRemoveSearchWithin)) {
        return;
      }
      $ctrl.onRemoveSearchWithin({
        searchWithinText: searchWithinText,
        bIsReset: false,
      });
    };

    $ctrl.previewText = function (id, title) {
      PreviewTextModalService.open(id, title);
    };

    /**
     * API to be used by faceted-search
     */
    $ctrl.api = {
      update: updateResultsList,
      updateSources: updateSources,
      resetPage: $ctrl.resetPage,
      setPage: $ctrl.setPage,
    };

    $ctrl.$onInit = function () {
      if (angular.isUndefined($ctrl.filterConfigs)) {
        //  getFilterConfigs() returns a promise. we will use the data in the promise and assign
        SearchService.getFilterConfigs().then(function (data) {
          $ctrl.filterConfigs = data;
        });
      }
    };
  }
}());

(function () {
  angular
    .module('isearchApp')
    .factory('UserService', UserService);

  UserService.$inject = ['$http', 'URL_BASE', '$q', 'IS_AUTH_ENABLED'];

  function UserService ($http, URL_BASE, $q, IS_AUTH_ENABLED) {
    var configParams = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: IS_AUTH_ENABLED,
    };

    var authenticate = function (user, password, rememberMe) {
      var formData = {
        j_username: angular.isDefined(user) ? user : '',
        j_password: angular.isDefined(password) ? password : '',
        'remember-me': rememberMe,
        submit: 'Login',
      };

      configParams.headers['Content-Type'] = 'application/x-www-form-urlencoded';

      var allProms = [
        // search auth
        $http.post(
          URL_BASE + '/isearch-search/api/authentication',
          $.param(formData), configParams
        ),
      ];

      return $q.all(allProms).then(function (responses) {
        return responses[0].data;
      });
    };

    var logout = function () {
      var allProms = [
        // search logout
        $http.post(
          URL_BASE + '/isearch-search/api/logout',
          {}, configParams
        ),
      ];

      return $q.all(allProms).then(function (response) {
        return response[0].data;
      });
    };

    var userService = {
      // getUserBySharepointID: getUserBySharepointID,
      authenticate: authenticate,
      logout: logout,
    };

    return userService;
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('NavbarController', NavbarController);

  NavbarController.$inject = ['$state', 'Auth', 'CUSTOM_EXTERNAL_LINK', 'ConfigService'];

  function NavbarController ($state, Auth, CUSTOM_EXTERNAL_LINK, ConfigService) {
    var vm = this;

    vm.isNavbarCollapsed = true;
    vm.isAuthenticated = Auth.isAuthenticated;
    vm.isAdAuth = Auth.isActiveDirectoryAuthentication;
    vm.sourceAuthorisationEnabled = ConfigService.getSearchConfig('sourceAuthorisationEnabled');
    vm.getUserSources = Auth.getUserSources;

    /*     ProfileService.getProfileInfo().then(function (response) {
      vm.inProduction = response.inProduction;
      vm.swaggerEnabled = response.swaggerEnabled;
    }); */

    vm.login = login;
    vm.logout = logout;
    vm.toggleNavbar = toggleNavbar;
    vm.collapseNavbar = collapseNavbar;
    vm.$state = $state;
    vm.user = null;
    vm.customExternalLink = CUSTOM_EXTERNAL_LINK || null;

    function login () {
      collapseNavbar();
      Auth.authorize();
    }

    function logout () {
      collapseNavbar();
      Auth.logout();

      if ($state.current.name === 'app.dashboard') {
        $state.reload();
      }
      else {
        $state.go('app.dashboard');
      }
    }

    function updateUser (userModel) {
      vm.user = userModel;
    }

    function toggleNavbar () {
      vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
    }

    function collapseNavbar () {
      vm.isNavbarCollapsed = true;
    }

    vm.$onInit = function () {
      Auth.updateUserCallback(updateUser);
      Auth.initUser();
    };
  }
}());

(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchWithin', {
      bindings: {
        api: '=',
        onSearchWithinUpdate: '&',
        // Triggers $ctrl.triggerSearchWithinUpdate (searchWithinText) in facetedSearchComponent
      },
      templateUrl: 'app/components/faceted-search-within/faceted-search-within.component.html',
      controller: facetedSearchWithin,
    });

  facetedSearchWithin.$inject = ['SearchService'];

  function facetedSearchWithin (SearchService) {
    var $ctrl = this;
    $ctrl.searchWithinText = '';
    $ctrl.searchWithinList = [];

    $ctrl.addSearchWithin = function (bIsReset) {
      if ($ctrl.searchWithinText.length <= 0) {
        return;
      }

      if ($ctrl.searchWithinList.indexOf($ctrl.searchWithinText.toLowerCase()) !== -1) {
        return;
      }

      $ctrl.searchWithinList.push($ctrl.searchWithinText.toLowerCase());
      $ctrl.searchWithinText = '';
      if (bIsReset !== true) {
        $ctrl.updateSearchWithin();
      }
    };

    /**
     * convert searchWithin json to js values
     * comes in form [{field: xx, value: xx}, {} ...]
     * @param {Array} searchWithin
     */
    $ctrl.setSearchWithinFromCriteria = function (searchWithin) {
      $ctrl.resetSearchWithin();

      if (searchWithin.length > 0) {
        _.forEach(searchWithin, function (obj) {
          $ctrl.searchWithinText = obj.value;
          $ctrl.addSearchWithin(true);
        });
      }

      $ctrl.updateSearchWithin(true);
      return $ctrl.searchWithinList;
    };

    $ctrl.removeSearchWithin = function (keyToRemove) {
      _.remove($ctrl.searchWithinList, function (key) {
        return key === keyToRemove;
      });
      $ctrl.updateSearchWithin();
    };


    $ctrl.updateSearchWithin = function (bIsReset) {
      if (angular.isFunction($ctrl.onSearchWithinUpdate)) {
        $ctrl.onSearchWithinUpdate({
          searchWithinList: $ctrl.searchWithinList,
          bIsReset: bIsReset,
        });
      }
    };

    /**
     * Reset text in search within field
     * @param {boolean} bIsReset
     */
    $ctrl.resetSearchWithin = function () {
      $ctrl.searchWithinText = '';
      $ctrl.searchWithinList = [];
    };

    /**
     * Get available autocomplete values from user input
     * @param {String} textVal
     */
    $ctrl.getAutocomplete = function (textVal) {
      return SearchService.execGetAutocomplete(textVal)
        .then(SearchService.buildAutocomplete);
    };


    $ctrl.api = {
      setSearchQuery: $ctrl.setSearchQuery,
      resetSearchWithin: $ctrl.resetSearchWithin,
      removeSearchWithin: $ctrl.removeSearchWithin,
      setSearchWithinFromCriteria: $ctrl.setSearchWithinFromCriteria,
    };
  }
}());

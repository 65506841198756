(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('TripleSearchModalService', TripleSearchModalService);

  TripleSearchModalService.$inject = ['$uibModal'];

  function TripleSearchModalService ($uibModal) {
    var service = {
      open: open,
      close: close,
    };

    var modalInstance = null;
    var resetModal = function () {
      modalInstance = null;
    };

    return service;

    function close () {
      modalInstance.dismiss('cancel');
    }

    function open (scope) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/triple-search/triple-search.modal.html',
        controller: 'TripleSearchModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        scope: scope,
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('LoginModalController', LoginModalController);
  LoginModalController.$inject = ['$timeout', 'Auth', 'curError'];

  function LoginModalController ($timeout, Auth, curError) {
    var vm = this;
    vm.cancel = cancel;
    vm.login = login;
    vm.requestResetPassword = requestResetPassword;

    vm.password = null;
    vm.rememberMe = true;
    vm.username = null;

    vm.curError = curError;

    $timeout(function () {
      angular.element('#username').focus();
    });
    function cancel () {
      Auth.cancel();
    }

    function login (event) {
      event.preventDefault();
      Auth.login(vm.username, vm.password, vm.rememberMe);
    }

    function requestResetPassword () {
      Auth.resetPassword();
    }
  }
}());

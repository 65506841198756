(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('customInterceptor', customInterceptor);

  customInterceptor.$inject = ['$q', '$cookies'];

  function customInterceptor ($q, $cookies) {
    var service = {
      request: customRequest,
    };

    return service;

    function customRequest (config) {
      /**
       * Custom interceptor allows project based customization of requests
       * By default this function should NOT change the config values.
       *
       * Example below deletes sortBy parameter when a search request is made
       *
          if (config.url && config.url.slice(-25) === '/isearch-search/v3/search') {
            if (config.data && config.data.sortBy) {
              delete config.data.sortBy;
            }
          }
       */
      return config;
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp', [
      'ngStorage',
      'ngCookies',
      'ngAnimate',
      'ngSanitize',
      'ngCacheBuster',
      'ui.bootstrap',
      'ui.router',
      'ui.select',
      // jhipster-needle-angularjs-add-module JHipster will add new module here
      'toastr',
      'cgBusy',
      'angularMoment',
      'xeditable',
      'daterangepicker',
      'moment-picker',
    ])
    .run(run);

  run.$inject = ['stateHandler', 'LoginModalService', '$rootScope', 'editableOptions', 'editableThemes'];

  function run (stateHandler, LoginModalService, $rootScope, editableOptions, editableThemes) {
    stateHandler.initialize();


    /*     $rootScope.$on('isearchApp.http401Error', function () {
      LoginModalService.open();
    }); */

    editableOptions.theme = 'bs3';
    editableThemes.bs3.inputClass = 'input-transparent';
    editableThemes.bs3.buttonsClass = 'btn-xs';
  }
}());

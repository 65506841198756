/* global vis */
(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('RelationshipBrowserModalController', RelationshipBrowserModalController);
  RelationshipBrowserModalController.$inject = ['$scope', '$uibModalInstance', 'KmService', 'SearchService'];

  function RelationshipBrowserModalController ($scope, $uibModalInstance, KmService, SearchService) {
    var vm = this;
    vm.history = [];
    vm.loadingRelations = false;
    var MAX_EDGES = 20; // maximum number of relationships to display

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.triggerSearch = function (searchQuery) {
      $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', searchQuery);
      vm.cancel(true);
    };

    vm.isElementSelected = function (element) {
      return element === vm.selectedElement;
    };

    vm.bindClass = function (element) {
      return {
        'label-success': vm.isElementSelected(element),
        pointable: !vm.isElementSelected(element),
      };
    };

    vm.updateSuggestConcept = function () {
      vm.suggestConcept = vm.selectedConcept;
      vm.selectedIndividual = null;
      $('#relationshipBrowser').html('');
      resetBrowserHistory();
    };

    function resetBrowserHistory () {
      vm.history = [];
    }
    function constructNetworkData (response, size) {
      var slicedNodes = response.nodes;
      var slicedEdges = _.slice(response.edges, 0, Math.min(response.edges.length, size));

      if (response.edges.length > size) {
        var toEdges = _.map(slicedEdges, 'to');
        console.log('toEdges', toEdges);
        var fromEdges = _.map(slicedEdges, 'from');
        console.log('fromEdges', fromEdges);

        var validNodeIds = _(toEdges).concat(fromEdges).uniq().value();
        console.log('validNodeIds', validNodeIds);

        slicedNodes = _.filter(response.nodes, function (node) {
          return _.indexOf(validNodeIds, node.id) > -1;
        });
        console.log('slicedNodes', slicedNodes);
      }
      console.log('return', {
        nodes: new vis.DataSet(slicedNodes),
        edges: new vis.DataSet(slicedEdges),
      });

      return {
        nodes: new vis.DataSet(slicedNodes),
        edges: new vis.DataSet(slicedEdges),
      };
    }
    function getRelations (element) {
      vm.loadingRelations = true;
      vm.selectedIndividual = element.suggest ? element.suggest : element.label;
      vm.selectedElement = element;
      vm.history.push(element);
      if (element.type) {
        vm.selectedConcept = _.find(vm.conceptsList, ['url', element.type]).label;
      }
      KmService.getRelationsById(element.id).then(function (response) {
        var data = constructNetworkData(response, MAX_EDGES);

        var options = {
          height: '300px',
          width: '100%',
          interaction: {
            hover: true,
          },
          physics: {
            barnesHut: {
              damping: 1.0,
              avoidOverlap: 0.02,
            },
          },
        };

        createNetwork(data, options);
        vm.loadingRelations = false;
      });
    }

    function createNetwork (data, options) {
      var container = document.getElementById('relationshipBrowser');
      var network = new vis.Network(container, data, options);

      network.on('click', function (properties) {
        if (vm.loadingRelations) {
          return;
        }
        var ids = properties.nodes;
        var clickedNodes = data.nodes.get(ids);
        if (clickedNodes[0] && clickedNodes[0].id && clickedNodes[0].color !== 'rgba(96,210,252,1)') {
          getRelations(clickedNodes[0]);
        }
      });
    }

    loadConcepts();

    function loadConcepts () {
      KmService.getConcepts().then(function (response) {
        vm.conceptsList = response;
      });
    }

    vm.getAutocomplete = function (textVal, type) {
      return SearchService.execGetAutocomplete(textVal, type)
        .then(SearchService.buildAutocomplete);
    };

    vm.trackBack = function (index) {
      if (vm.loadingRelations) {
        return;
      }
      if (angular.isUndefined(vm.history[index])) {
        return;
      }
      var element = vm.history[index];

      if (element.suggest) {
        vm.selectedConcept = vm.suggestConcept;
      }

      vm.history = vm.history.splice(0, index);
      getRelations(element);
    };

    $scope.onSelect = function ($model) {
      resetBrowserHistory();
      getRelations($model);
    };
  }
}());

(function () {
  angular
    .module('isearchApp')
    .component('facetedSearch', {
      bindings: {
        api: '=',
        urlSearchParameters: '<', // {query : ""}
      },
      templateUrl: 'app/components/faceted-search/faceted-search.component.html',
      controller: facetedSearch,
    });

  facetedSearch.$inject = ['$rootScope', '$scope', '$state', 'SearchService', 'SearchResultModel', '$interval'];

  /**
     * Events this component will track:
     * facetedSearchResultsItem:tagSearchTriggered - Query String
     */
  function facetedSearch ($rootScope, $scope, $state, SearchService, SearchResultModel, $interval) {
    var $ctrl = this;
    $ctrl.searchResultModel = {};
    $ctrl.appliedFilters = {};
    $ctrl.appliedSort = {};
    $ctrl.currentQuery = '';
    $ctrl.curPage = 1;
    $ctrl.dateObject = {};
    $ctrl.yearObject = {};
    $ctrl.dateRange = {};
    $ctrl.yearRange = {};
    $ctrl.searchWithinList = [];
    $ctrl.source = '';

    /**
         * Watcher for binding the search query to the url
         */
    $scope.$watch('$ctrl.currentQuery', function (newValue, oldValue) {
      if (newValue.length > 0 && newValue !== oldValue) {
        $state.go('app.dashboard', { query: newValue }, { notify: false });
      }
    });

    $ctrl.$onInit = function () {
      $ctrl.populateEmbeddedSearchQuery();
    };

    var tryWithLimit = function (fx, errorMsg) {
      // difficulty in awaiting for all components loaded
      // because triggersearch has dependencies on all nested childs api to be initialized
      // will try every 100ms, until max of 3000ms is taken, then it will be cancelled.
      var searchAttempts = $interval(function () {
        var maxCount = 30;
        var myCount = 0;
        try {
          fx();
          $interval.cancel(searchAttempts);
        }
        catch (e) {
          myCount += 1;
          if (myCount >= maxCount) {
            console.error(errorMsg);
            $interval.cancel(searchAttempts);
          }
        }
      }, 100);
    };

    $ctrl.loadCriteria = function () {
      var criteriaID = $state.params.criteria;

      if (criteriaID) {
        tryWithLimit(function () {
          $ctrl.resetCurPage();

          $ctrl.source = '';
          SearchService.execGetCriteria(criteriaID)
            .then(function (data) {
              if (data) {
                $ctrl.triggerLoadSearch(data.request);
              }
            });
        }, 'error in loading child components, unable to trigger search in time');
      }
    };

    $ctrl.populateEmbeddedSearchQuery = function () {
      if ($state.params.query && $state.params.query.length > 0) {
        tryWithLimit(function () {
          $ctrl.triggerSearch($state.params.query);
        }, 'error in loading child components, unable to trigger search in time');
      }
      else if ($state.params.criteria) {
        this.loadCriteria();
      }
    };

    /**
         * Do search with provided query
         * Can be triggered from Searchbox / Filters / Date Range
         * @param {String} query
         */
    $ctrl.triggerSearch = function (query, searchMode, resetSources) {
      if (angular.isUndefined(searchMode) || searchMode === null) {
        searchMode = SearchService.SEARCH_MODE.NEW;
      }

      if (!query.length) {
        return;
      }

      resetSources = resetSources === false ? resetSources : true;

      if (searchMode !== SearchService.SEARCH_MODE.PAGINATION) {
        //  reset page if not a change page query
        $ctrl.resetCurPage();
      }

      if (searchMode === SearchService.SEARCH_MODE.NEW) {
        //  new query triggered
        $ctrl.currentQuery = query;
        $ctrl.appliedFilters = {}; // clear applied filters with new search
        $ctrl.searchFilterApi.resetFilters();
        $ctrl.dateObject = {};
        $ctrl.dateRange = {};
        $ctrl.yearObject = {};
        $ctrl.yearRange = {};

        $ctrl.searchFilterApi.resetDateRange();
        $ctrl.searchFilterApi.resetYearRange();

        $ctrl.searchWithinList = [];
        $ctrl.searchWithinApi.resetSearchWithin();

        resetSources = true; //  new query always resets the sources tabs
      }

      $ctrl.execAggregations().then(function () {
        if (resetSources) {
          $ctrl.source = ''; //  reset source
        }

        $ctrl.currentQuery = query;
        $ctrl.execSearchQuery(searchMode);

        if (resetSources) {
          //  only update categories if its a new query
          $ctrl.execCategories();
        }
      });
    };

    /**
         * Generate Search Params for SearchService execSearch API using list of filters & date range
         */
    $ctrl.buildSearchParam = function (searchMode) {
      // var topicsShould = SearchService.buildFilterParam($ctrl.appliedFilters);
      var topicsMust = SearchService.buildFilterParam($ctrl.appliedFilters);
      var offset = SearchService.getOffsetByPage($ctrl.curPage);
      var rangeQueryList = [];

      // add modified date range query to list of queries if exist
      var modifiedDateRange = SearchService.buildDateRange($ctrl.dateObject);
      if (angular.isDefined(modifiedDateRange)) {
        rangeQueryList.push(modifiedDateRange);
      }

      // add modified year range query to list of queries if exist
      var modifiedYearRange = SearchService.buildYearRange($ctrl.yearObject);
      if (angular.isDefined(modifiedYearRange)) {
        rangeQueryList = rangeQueryList.concat(modifiedYearRange);
      }

      var param = {
        // topicsShould: angular.isDefined(topicsShould) ? topicsShould : [],
        topicsMust: angular.isDefined(topicsMust) ? topicsMust : [],
        rangeQuery: rangeQueryList,
        from: angular.isDefined(offset) ? Number(offset) : 0,
        searchMode: searchMode,
        sortBy: $ctrl.appliedSort,
      };

      if (searchMode !== SearchService.SEARCH_MODE.NEW) {
        param.id = angular.isDefined($ctrl.searchResultModel.id) ? $ctrl.searchResultModel.id : null;
      }

      if ($ctrl.source.length > 0) {
        param.source = $ctrl.source;
      }

      if ($ctrl.searchWithinList.length <= 0) {
        return param;
      }
      //  optional
      var searchWithin = SearchService.buildSearchWithin($ctrl.searchWithinList);
      param.searchWithin = searchWithin;

      return param;
    };

    /**
         *  Calls search API and updates the list of results
         * @param {String} searchQuery
         */
    $ctrl.execSearchQuery = function (searchMode) {
      var param = $ctrl.buildSearchParam(searchMode);

      $ctrl.searchNlpResultApi.execGetNlpResult($ctrl.currentQuery);
      SearchService
        .execSimpleSearch($ctrl.currentQuery, param)
        .then(function (data) {
          $ctrl.searchResultModel = SearchResultModel(data);
          $ctrl.searchResultsApi.update($ctrl.currentQuery, $ctrl.searchResultModel);
          $ctrl.searchSuggestApi.execGetSuggestion($ctrl.currentQuery);
          $ctrl.searchCorrectionApi.execGetCorrection($ctrl.currentQuery);
          if (angular.isDefined($ctrl.searchResultModel.id)) {
            $ctrl.searchBarApi.setCanSaveSearch(true);
          }
        });
    };

    /**
         * uses Request Object to trigger search without building new param object
         * @param {Object} request
         */
    $ctrl.execSearchQueryFromCriteria = function (request) {
      SearchService
        .execSimpleSearchFromCriteria(request)
        .then(function (data) {
          $ctrl.searchResultModel = SearchResultModel(data);
          $ctrl.searchResultsApi.update($ctrl.currentQuery, $ctrl.searchResultModel);
          $ctrl.searchSuggestApi.execGetSuggestion($ctrl.currentQuery);
          $ctrl.searchCorrectionApi.execGetCorrection($ctrl.currentQuery);
          if (angular.isDefined($ctrl.searchResultModel.id)) {
            $ctrl.searchBarApi.setCanSaveSearch(true);
          }
          $ctrl.execCategoriesForCriteria(request, $ctrl.source);
        });
    };

    /**
         * get source tabs of query
         * @param {Object} selectedSource
         */
    $ctrl.execCategories = function (selectedSource) {
      var param = $ctrl.buildSearchParam();
      SearchService.execCategories($ctrl.currentQuery, _.omit(param, 'source'))
        .then(function (data) {
          $ctrl.searchResultsApi.updateSources(data.aggregations, selectedSource);
        });
    };

    /**
         * uses request object to get soruces
         * @param {Object} request
         * @param {String} selectedSource
         */
    $ctrl.execCategoriesForCriteria = function (request, selectedSource) {
      SearchService.execCategoriesFromCriteria(request)
        .then(function (data) {
          $ctrl.searchResultsApi.updateSources(data.aggregations, selectedSource);
        });
    };

    $ctrl.execAggregations = function () {
      // build request parmas with applied filters
      const myParams = $ctrl.buildSearchParam();

      return SearchService
        .execGetAllAggregation($ctrl.currentQuery, null, myParams)
        .then(function (data) {
          $ctrl.searchTagcloudApi.initTagcloud(data);
          $ctrl.searchFilterApi.setAggregations(data);
        });
    };

    //  Tag Search triggered from FacetedSearchResultsItem Component
    $scope.$on('facetedSearchResultsItem:tagSearchTriggered', function (event, query) {
      $ctrl.searchBarApi.updateQuery(query);
      $ctrl.triggerSearch(query, SearchService.SEARCH_MODE.NEW);
    });

    //  Suggestion Search triggered from SearchSuggest component
    $ctrl.triggerSearchSuggest = function (query) {
      $ctrl.searchBarApi.updateQuery(query);
      $ctrl.triggerSearch(query, SearchService.SEARCH_MODE.NEW);
    };

    //  Suggestion Search triggered from SearchCorrection component
    $ctrl.triggerSearchCorrection = function (query) {
      $ctrl.searchBarApi.updateQuery(query);
      $ctrl.triggerSearch(query, SearchService.SEARCH_MODE.NEW);
    };

    /**
         * Triggered from searchFilterComponent to assign own AppliedFilters List
         * @param {AggregationModel[]} aggregations
         */
    $ctrl.triggerFilterUpdate = function (aggregations, bIsReset) {
      if (_.isEqual(aggregations, $ctrl.appliedFilters)) {
        return;
      }
      $ctrl.appliedFilters = angular.copy(aggregations);
      if (bIsReset !== true) {
        $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.FILTER, true);
      }
    };

    /**
         * Triggered from searchFilterComponent build dateRange params from dateObject
         * @param {Object} dateObject
         */
    $ctrl.triggerDateRangeUpdate = function (dateObject, bIsReset) {
      if ($ctrl.dateObject.start === dateObject.start
                && $ctrl.dateObject.end === dateObject.end) {
        //  do not trigger search if no change
        return;
      }
      $ctrl.dateObject = dateObject;
      $ctrl.dateRange = SearchService.buildDateRange($ctrl.dateObject);
      if (bIsReset !== true) {
        $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.FILTER, true);
      }
    };

    /**
     * Triggered from searchFilterComponent build yearRange params from yearObject
     * @param {Object} yearObject
     */
    $ctrl.triggerYearRangeUpdate = function (yearObject, bIsReset) {
      if ($ctrl.yearObject.start === yearObject.start
        && $ctrl.yearObject.end === yearObject.end) {
        return;
      }
      $ctrl.yearObject = yearObject;
      $ctrl.yearRange = SearchService.buildYearRange($ctrl.yearObject);
      if (bIsReset !== true) {
        $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.FILTER, true);
      }
    };

    $ctrl.triggerResultPageUpdate = function (curPage) {
      if ($ctrl.curPage === curPage) {
        //  do not trigger search if no change
        return;
      }
      $ctrl.curPage = curPage;
      $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.PAGINATION, false);
    };

    /**
         * Triggered from searchResultComponent to call searchFilterApi to refresh AppliedFilters
         * @param {String} name //  ie. docFormat
         * @param {String} key // ie. Excel
         */
    $ctrl.removeFilter = function (name, key) {
      $ctrl.searchFilterApi.uncheckOption(name, key);
    };

    /**
         * Triggered from searchResultComponent to call searchFilterApi to refresh dateRangeParam
         * @param {String} dateKey //ie. gte, lte, from, to
         */
    $ctrl.removeDateRange = function (dateKey) {
      $ctrl.searchFilterApi.uncheckDate(dateKey);
    };

    $ctrl.removeYearRange = function (yearKey) {
      $ctrl.searchFilterApi.uncheckYear(yearKey);
    };

    /**
         * Triggered from searchFilterComponent to update search within text
         * @param {AggregationModel[]} aggregations
         */
    $ctrl.triggerSearchWithinUpdate = function (searchWithinList, bIsReset) {
      if (_.isEqual(searchWithinList, $ctrl.searchWithinList)) {
        return;
      }
      $ctrl.searchWithinList = angular.copy(searchWithinList);
      if (bIsReset !== true) {
        $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.SEARCH_WITHIN, true);
      }
    };

    /**
         * Triggered from searchResultComponent to call searchFilterApi to refresh searchWithin
         */
    $ctrl.removeSearchWithin = function (searchWithinText) {
      $ctrl.searchWithinApi.removeSearchWithin(searchWithinText);
    };

    $ctrl.resetCurPage = function () {
      $ctrl.curPage = 1;
      if ($ctrl.searchResultsApi) {
        $ctrl.searchResultsApi.resetPage();
      }
    };

    /**
         * Triggered in facetedSearchResultComponent to refresh source
         * @param {String} source
         */
    $ctrl.triggerSourceUpdate = function (source) {
      if (_.isEqual(source, $ctrl.source)) {
        return;
      }

      $ctrl.source = '';
      if ($ctrl.source !== SearchService.SOURCE_TYPES.ALL) {
        $ctrl.source = source;
      }

      $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.SOURCE, false);
    };

    $ctrl.triggerSortChange = function (option) {
      $ctrl.setAppliedSort(option);
      $ctrl.triggerSearch($ctrl.currentQuery, SearchService.SEARCH_MODE.SOURCE, false);
    };

    $ctrl.setAppliedSort = function (option) {
      if (option.value && option.order) {
        $ctrl.appliedSort = {
          field: option.value,
          order: option.order,
        };
      }
      else {
        $ctrl.appliedSort = {};
      }
    };

    /**
         * triggered in SaveSearchModalController
         * @param {*} searchName
         */
    $ctrl.triggerSaveSearch = function (searchName) {
      var SAVE_SEARCH_ERR = {
        INVALID_NAME: '<strong>Failed to save search!</strong> Please enter a valid Title',
        INVALID_RESULT: '<strong>Failed to save search!</strong> Please execute a search first',
      };
      if (searchName.length <= 0) {
        $rootScope.$emit('facetedSearchComponent:triggerSaveSearch', SAVE_SEARCH_ERR.INVALID_NAME);
        return false;
      }

      if (angular.isUndefined($ctrl.searchResultModel.id)) {
        $rootScope.$emit('facetedSearchComponent:triggerSaveSearch', SAVE_SEARCH_ERR.INVALID_RESULT);
        return false;
      }

      var param = $ctrl.buildSearchParam(SearchService.SEARCH_MODE.SAVE_SEARCH);
      SearchService
        .execSaveSearch($ctrl.currentQuery, param, searchName)
        .then(function (data) {
        });
      return true;
    };

    /**
     * restore and trigger search criteria from request object
     * triggered in SaveSearchModalController
     * @param {Request Object} request
     */
    $ctrl.triggerLoadSearch = function (request) {
      var query = request.query;
      if (!query.length) {
        return false;
      }
      // set search bar query
      $ctrl.currentQuery = query;
      $ctrl.searchBarApi.setSearchQuery(query);

      // set applied sort
      if (angular.isDefined(request.sortBy)) {
        var sortBy = request.sortBy;
        $ctrl.setAppliedSort({ value: sortBy.field, order: sortBy.order });
      }
      else {
        $ctrl.setAppliedSort({});
      }

      // reset appliedfilters and populate according to criteria request
      $ctrl.appliedFilters = {};
      const requestFilters = request.topicsQuery.must;
      requestFilters.forEach(function (filter) {
        const field = filter.field;
        const value = filter.value;
        $ctrl.appliedFilters[field] = [value];
      });

      // set source
      $ctrl.source = request.source.length > 0 ? request.source[0] : '';

      // trigger criteria search
      $ctrl.execSearchQueryFromCriteria(request);

      // update UI filters
      $ctrl.execAggregations().then(function () {
        $ctrl.searchFilterApi.updateFilterListFromCriteria(request.topicsQuery.must);

        const rangeQueryListGroup = _(SearchService.parseDateRangeFromCriteria(request.rangeQuery))
          .groupBy('uiTitle')
          .value();

        $ctrl.searchFilterApi.updateDateRangeFromCriteria(rangeQueryListGroup['Modified Date'] || []);

        $ctrl.searchFilterApi.updateYearRangeFromCriteria(rangeQueryListGroup.Year || []);
      });
      $ctrl.searchResultsApi.setPage(SearchService.getPageByOffset(request.from));
      $ctrl.searchWithinApi
        .setSearchWithinFromCriteria(request.searchWithin);

      return true;
    };
  }
}());

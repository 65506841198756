(function () {
  angular
    .module('isearchApp')
    .factory('ConfigService', ConfigService);

  ConfigService.$inject = ['SEARCH_SETTINGS'];

  function ConfigService (SEARCH_SETTINGS) {
    var getSearchConfig = function (configName) {
      if (SEARCH_SETTINGS && SEARCH_SETTINGS[configName]) {
        return SEARCH_SETTINGS[configName];
      }
      return null;
    };
    var configService = {
      getSearchConfig: getSearchConfig,
    };
    return configService;
  }
}());

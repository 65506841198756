(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('DashboardController', DashboardController);

  DashboardController.$inject = ['Auth', '$stateParams'];

  function DashboardController (Auth, $stateParams) {
    var vm = this;

    /**
    * Login Modal for authenticating user
    */
    /*     vm.openLoginModal = function () {
      $uibModal.open({
        templateUrl: 'app/modals/login/login.modal.html',
        controller: 'LoginModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
      });
    };

    /**
    * User Authentication and get UserModel object from DB. set to local storage
    */
    /* vm.getUser = function () {
      if (!$localStorage.userModel) {
        vm.openLoginModal();
      }
    }; */

    vm.$onInit = function () {
      // vm.getUser();
      Auth.authorize();
      vm.stateParams = $stateParams;
    };

    // vm.init();
  }
}());

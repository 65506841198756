(function () {
  angular
    .module('isearchApp')
    .factory('SearchResultModel', SearchResultFactory);

  SearchResultFactory.$inject = ['DocumentModel', 'SearchService'];

  function SearchResultFactory (DocumentModel, SearchService) {
    function SearchResultModel (data) {
      if (angular.isUndefined(data)) {
        return undefined;
      }

      var result = {};

      result = {
        total: angular.isDefined(data.total) ? data.total : 0,
        id: angular.isDefined(data.id) ? data.id : null,
        size: angular.isDefined(data.size) ? data.size : SearchService.RESULTS_PER_PAGE,
        documents: [],
      };

      if (angular.isDefined(data.documents)) {
        result.documents = DocumentModel.buildFromArray(data.documents, result.size);
      }

      return result;
    }
    /**
     * Static methods , assigned to class
     * Instance ('this') is not available in static context
     */

    return SearchResultModel;
  }
}());

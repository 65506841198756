(function () {
  'use strict';

  angular.module('isearchApp')

    .filter('percentage', ['$filter', function ($filter) {
      return function (input, decimals) {
        return $filter('number')(input * 100, decimals) + '%';
      };
    }])

    .filter('formatComputedValue', ['UtilityService', function (UtilityService) {
      return function (value) {
        if (angular.isNumber(value) && UtilityService.isNumberFloat(value)) {
          return value.toFixed(2);
        }
        if (typeof (value) === typeof (true)) {
          return (value + '').toUpperCase();
        }
        return value;
      };
    }])

    .filter('groupStatusLabelHtml', ['ResourceModel', function (ResourceModel) {
      return function (group) {
        var labelClass = group.status === ResourceModel.GROUPS_STATUS.SUCCESS ? 'label-primary' : 'label-danger';

        return '<span class="label ' + labelClass + '">' + group.status + '</span>';
      };
    }])

    .filter('sanitize', ['$sce', function ($sce) {
      return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
      };
    }])

    .filter('sanitizeUrl', ['$sce', function ($sce) {
      return function (url) {
        return $sce.trustAsResourceUrl(url);
      };
    }])
    .filter('propsFilter', function () {
      return function (items, props) {
        var out = [];

        if (angular.isArray(items)) {
          var keys = Object.keys(props);

          items.forEach(function (item) {
            if (!item) return;
            var itemMatches = false;

            for (var i = 0; i < keys.length; i += 1) {
              var prop = keys[i];
              var text = props[prop].toLowerCase();
              if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                itemMatches = true;
                break;
              }
            }

            if (itemMatches) {
              out.push(item);
            }
          });
        }
        else {
          // Let the output be the input untouched
          out = items;
        }

        return out;
      };
    })
    .filter('capitalize', function () {
      return function (input) {
        return (angular.isString(input) && input.length > 0)
          ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
      };
    })
    .filter('unique', function () {
      // ripped from: https://github.com/angular-ui/angular-ui-OLDREPO/blob/master/modules/filters/unique/unique.js
      /* eslint-disable */
      return function (items, filterOn) {
        if (filterOn === false) {
          return items;
        }

        if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
          var hashCheck = {},
            newItems = [];

          var extractValueToCompare = function (item) {
            if (angular.isObject(item) && angular.isString(filterOn)) {
              return item[filterOn];
            }
            return item;
          };

          angular.forEach(items, function (item) {
            var valueToCheck,
              isDuplicate = false;

            for (var i = 0; i < newItems.length; i += 1) {
              if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                isDuplicate = true;
                break;
              }
            }
            if (!isDuplicate) {
              newItems.push(item);
            }
          });
          items = newItems;
        }
        return items;
      };
    });
}());

/* global vis */
(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('RelationshipViewerModalController', RelationshipViewerModalController);
  RelationshipViewerModalController.$inject = ['$scope', '$uibModalInstance', '$http', 'Auth', 'URL_BASE', 'KmService',
    'searchTerm'];

  function RelationshipViewerModalController ($scope, $uibModalInstance, $http, Auth, URL_BASE, KmService, searchTerm) {
    var vm = this;

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    // Sample searchTerm
    getRelations(searchTerm);

    function getRelations () {
      KmService.getRelations(searchTerm).then(function (response) {
        var nodes = new vis.DataSet(response.nodes);
        var edges = new vis.DataSet(response.edges);

        var data = {
          nodes: nodes,
          edges: edges,
        };

        var options = {
          height: '800px',
          width: '100%',
          interaction: {
            hover: true,
          },
          physics: {
            barnesHut: {
              damping: 1.0,
              avoidOverlap: 0.02,
            },
          },
        };

        createNetwork(data, options);
      });
    }

    function createNetwork (data, options) {
      var container = document.getElementById('relationshipViewer');
      var network = new vis.Network(container, data, options);

      network.on('click', function (properties) {
        var ids = properties.nodes;
        var clickedNodes = data.nodes.get(ids);
        if (clickedNodes[0].label) {
          $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', clickedNodes[0].label);
          vm.cancel(true);
        }
      });
    }
  }
}());

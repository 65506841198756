(function () {
  angular
    .module('isearchApp')
    .factory('UserModel', UserFactory);

  // UserModel.$inject = ['UserService'];

  function UserFactory () {
    function UserModel (data) {
      var user = {};

      user = {
        id: angular.isDefined(data.id) ? data.id : 1,
        groups: angular.isDefined(data.groups) ? data.groups : [],
        division: angular.isDefined(data.division) ? data.division : null,
        designationType: angular.isDefined(data.designationType) ? data.designationType : null,
        firstName: angular.isDefined(data.firstName) ? data.firstName : 'User',
        roles: angular.isDefined(data.roles) ? data.roles : [],
        expirationTime: angular.isDefined(data.expirationTime) ? data.expirationTime : null,
        signature: angular.isDefined(data.signature) ? data.signature : null,
        repoSources: angular.isDefined(data.repoSources) ? data.repoSources : [],
      };

      return user;
    }
    /**
     * Static methods , assigned to class
     * Instance ('this') is not available in static context
     */
    UserModel.build = function (responseData) {
      return new UserModel(responseData);
    };

    return UserModel;
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('SaveSearchModalController', SaveSearchModalController);
  SaveSearchModalController.$inject = ['$rootScope', '$timeout', 'SaveSearchModalService', 'saveSearchCallback'];

  function SaveSearchModalController ($rootScope, $timeout, SaveSearchModalService, saveSearchCallback) {
    var vm = this;
    vm.cancel = cancel;
    vm.save = save;

    vm.title = '';
    vm.errMsg = '';

    //  saveSearchCallback passed from SaveSearchModalService.open();
    vm.saveSearchCallback = saveSearchCallback;

    vm.$onInit = function () {
      $timeout(function () {
        angular.element('#title').trigger('focus');
      }, 500); // 500ms to wait for modal transition to complete
    };

    function cancel () {
      // Auth.cancel();
      SaveSearchModalService.close();
    }

    function save (event) {
      event.preventDefault();
      if (angular.isFunction(vm.saveSearchCallback)) {
        if (vm.saveSearchCallback({
          searchName: vm.title,
        }) === true) { //  successfully saved search
          cancel();
        }
      }
      // Auth.login(vm.username, vm.password, vm.rememberMe);
    }

    $rootScope.$on('facetedSearchComponent:triggerSaveSearch', function (event, errMsg) {
      vm.errMsg = errMsg;
    });
  }
}());

(function () {
  angular
    .module('isearchApp')
    .factory('UserADService', UserADService);

  UserADService.$inject = ['$http', 'URL_BASE', 'AD_AUTH_ENDPOINT'];

  function UserADService ($http, URL_BASE, AD_AUTH_ENDPOINT) {
    var getUserAuth = function () {
      return $http.get(URL_BASE + AD_AUTH_ENDPOINT).then(function (response) {
        return response.data;
      });
    };

    var userADService = {
      getUserAuth: getUserAuth,
    };

    return userADService;
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('errorHandlerInterceptor', errorHandlerInterceptor);

  errorHandlerInterceptor.$inject = ['$q', '$rootScope', '$cookies', '$localStorage', 'IS_AD_AUTH', 'AD_AUTH_ENDPOINT', '$injector'];

  function errorHandlerInterceptor ($q, $rootScope, $cookies, $localStorage, IS_AD_AUTH, AD_AUTH_ENDPOINT, $injector) {
    var service = {
      responseError: responseError,
    };

    return service;

    function checkTimeout () {
      const user = $localStorage.userModel;
      if (!user) {
        return false;
      }
      const timeout = new Date(user.expirationTime);
      const now = new Date();
      if (now > timeout) {
        return true;
      }
      return false;
    }

    function responseError (response) {
      var responseStatus = Number(response.status);
      var responseURL = angular.isDefined(response.config.url) ? response.config.url : '';
      var emitString = '';
      switch (responseStatus) {
        case 401:
          // failed to authenticate
          emitString = 'isearchApp.http401';
          break;

        case 403:
          //  user does not have access
          emitString = 'isearchApp.http403';
          $cookies.remove('CSRF-TOKEN-UI');
          delete $localStorage.userModel;

          break;

        case 500:
          // case -1:
          //  internal server error
          if (responseURL.indexOf('/isearch-search/api/authentication') !== -1) {
            emitString = 'isearchApp.http500';
          }
          break;

        default:
          break;
      }

      if (emitString.length > 0) {
        if (responseURL.indexOf('/isearch-search/api/authentication') !== -1) {
          //  error during authentication. It will NOT affect AD requests
          emitString += 'Auth';
        }

        if (IS_AD_AUTH) {
          if (responseURL.indexOf(AD_AUTH_ENDPOINT) !== -1) {
            emitString += 'AuthAD';
          }
          else if (checkTimeout()) {
            emitString += 'AuthADTimeout';
            /**
             *  @todo, if its a timeout, re-authenticate and refresh signature
             *  however, need to distinguish between failed auth and timeout auth!
             *  https://stackoverflow.com/questions/18638211/how-can-i-send-request-again-in-response-interceptor
             * */
            // // session lost
            // var Session = $injector.get('Session');
            // var $http = $injector.get('$http');
            // // first create new session server-side
            // var defer = $q.defer();
            // var promiseSession = defer.promise;
            // Session.query({}, function () {
            //   defer.resolve();
            // }, function () {
            //   // error
            //   defer.reject();
            // });
            // // and chain request
            // var promiseUpdate = promiseSession.then(function () {
            //   return $http(response.config);
            // });
            // return promiseUpdate;
          }
          else {
            emitString += 'AuthAD';
          }
        }

        emitString += 'Error';
        $rootScope.$emit(emitString, response);
      }

      return $q.reject(response);
    }
  }
}());

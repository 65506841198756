(function () {
  angular
    .module('isearchApp')
    .component('searchBar', {
      bindings: {
        api: '=', //  update query value in search bar
        onSearchQuery: '&', // callback when user clicks on search button
        onSaveSearch: '&', //  callback when user clicks on save this search button
        onLoadSearch: '&', //  callback when user clicks on a search in view saved search modal
        urlSearchParameters: '<', // parameters embedded in url if any {query : null}
      },
      templateUrl: 'app/components/search-bar/search-bar.component.html',
      controller: searchBar,
    }).directive('searchBarWidgetHolder', searchBarWidgetDirective);

  searchBar.$inject = ['$rootScope', '$scope', 'SearchService', 'SaveSearchModalService', 'Auth',
    'RelationshipViewerModalService', 'RelationshipBrowserModalService', 'ConceptBrowserModalService', 'TripleSearchModalService', 'WidgetService', 'ConfigService'];

  searchBarWidgetDirective.$inject = ['WidgetService', '$timeout'];

  function searchBar (
    $rootScope, $scope, SearchService, SaveSearchModalService, Auth,
    RelationshipViewerModalService, RelationshipBrowserModalService, ConceptBrowserModalService, TripleSearchModalService, WidgetService, ConfigService
  ) {
    var $ctrl = this;
    $ctrl.searchVal = '';
    $ctrl.canSaveSearch = false;
    $ctrl.tooltipIsOpen = false;
    $ctrl.isAuthenticated = Auth.isAuthenticated();
    $ctrl.searchBarWidgetEnabled = WidgetService.isWidgetEnabled('searchBar');
    $ctrl.quoteSelectedQuery = ConfigService.getSearchConfig('quoteQueryOnSelect');
    $ctrl.tripleSearchBrowserEnabled = ConfigService.getSearchConfig('tripleSearchBrowserEnabled');
    $ctrl.relationshipBrowserEnabled = ConfigService.getSearchConfig('relationshipBrowserEnabled');
    $ctrl.conceptBrowserEnabled = ConfigService.getSearchConfig('conceptBrowserEnabled');
    $ctrl.conceptLabel = 'concept';

    $ctrl.$onInit = function () {
      if ($ctrl.urlSearchParameters.query) {
        const urlSearchQuery = $ctrl.urlSearchParameters.query;
        $ctrl.searchVal = urlSearchQuery;
      }
    };

    /**
     * Call Search when user submits form with search query
     */
    $ctrl.basicSearch = function () {
      if (!$ctrl.searchVal.length) {
        return;
      }
      if (angular.isFunction($ctrl.onSearchQuery)) {
        $ctrl.onSearchQuery({
          query: $ctrl.searchVal,
        });
      }
    };

    /**
     * Get available autocomplete values from user input
     * @param {String} textVal
     */
    $ctrl.getAutocomplete = function (textVal) {
      return SearchService.execGetAutocomplete(textVal)
        .then(SearchService.buildAutocomplete);
    };

    $ctrl.updateQuery = function (query) {
      $ctrl.searchVal = query;
    };

    $ctrl.saveSearch = function () {
      SaveSearchModalService.open($ctrl.onSaveSearch);
    };

    $ctrl.getSavedSearches = function () {
      SaveSearchModalService.openView($ctrl.onLoadSearch);
    };

    $ctrl.setCanSaveSearch = function (canSaveSearch) {
      $ctrl.canSaveSearch = canSaveSearch;
    };

    $ctrl.setSearchQuery = function (query) {
      $ctrl.searchVal = query;
    };


    $ctrl.openRelationshipViewer = function (searchTerm) {
      RelationshipViewerModalService.open(searchTerm, $scope);
    };

    $ctrl.openTripleSearch = function () {
      TripleSearchModalService.open($scope);
    };

    $ctrl.openRelationshipBrowser = function () {
      RelationshipBrowserModalService.open($scope);
    };

    $ctrl.openConceptBrowser = function () {
      ConceptBrowserModalService.open($scope);
    };

    $ctrl.isAuthenticated = function () {
      return Auth.isAuthenticated();
    };

    $ctrl.onSelect = function (item, model, label) {
      if ($ctrl.quoteSelectedQuery) {
        $ctrl.searchVal = '"' + $ctrl.searchVal + '"';
      }
      else {
        $ctrl.searchVal = $ctrl.searchVal;
      }
    };

    $ctrl.api = {
      updateQuery: $ctrl.updateQuery,
      setCanSaveSearch: $ctrl.setCanSaveSearch,
      setSearchQuery: $ctrl.setSearchQuery,
      basicSearch: $ctrl.basicSearch,
    };

    $ctrl.closeTooltipWindow = function () {
      $ctrl.tooltipIsOpen = false;
    };

    // $ctrl.init();
  }

  function searchBarWidgetDirective (WidgetService, $timeout) {
    return {
      template: '<search-bar-widget class="taiger_styles" style="display: block; margin:4px;"></search-bar-widget>',
      link: function (scope) { // plunkr to understand link vs controller: https://embed.plnkr.co/plunk/cbsRod
        const $ctrl = scope.$ctrl;
        const widgetProps = WidgetService.getWidgetProps('searchBar');
        const searchWidgetElement = document.querySelector('search-bar-widget');

        /**
         * Set props to the widget
         */
        Object.keys(widgetProps).forEach(function (key) {
          const value = widgetProps[key];
          WidgetService.setWidgetProp(searchWidgetElement, key, value);
        });
        /*
        * listens for 'searchClick' event from search bar widget. Event name is configurable
        */
        searchWidgetElement.addEventListener('searchClick', function (event) {
          const searchQuery = event.detail[0].query;
          $ctrl.searchVal = searchQuery;
          $ctrl.basicSearch();
        });
        /*
          * watches searchVal in this controller, and updates new values as the inputValue prop
         */
        scope.$watch('$ctrl.searchVal', function (newValue, oldValue) {
          if (searchWidgetElement) {
            // IE11 doesnt register change here, timeout to allow props to be set on next tick after init.
            $timeout(WidgetService.setWidgetProp, 0, true, searchWidgetElement, 'inputValue', newValue);
          }
        });
      },
    };
  }
}());

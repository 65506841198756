(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchResultsTags', {
      bindings: {
        doc: '=',
        filterConfigs: '=', //  list of available filters
      },
      templateUrl: 'app/components/faceted-search-results-item/faceted-search-results-tags/faceted-search-results-tags.component.html',
      controller: facetedSearchResultsTags,
    });

  facetedSearchResultsTags.$inject = ['$scope', 'KmService', 'Auth'];

  function facetedSearchResultsTags ($scope, KmService, Auth) {
    var $ctrl = this;
    $ctrl.showTags = false;
    $ctrl.isEditMode = false;
    $ctrl.hasSourceAuthorisation = false;
    $ctrl.tagSuggestions = {};
    $ctrl.isAuthenticated = function () {
      return Auth.isAuthenticated();
    };
    $ctrl.isRoleKnowledgeAuthorityUser = function () {
      return ($ctrl.isAuthenticated() && Auth.getUser().roles.indexOf('ROLE_KNOWLEDGE') !== -1);
    };

    $ctrl.toggleTagsView = function () {
      $ctrl.showTags = !$ctrl.showTags;
    };

    $ctrl.getKeyByValue = function (object, value) {
      return Object.keys(object).find(function (key) {
        return object[key] === value;
      });
    };

    $ctrl.removeTopic = function (topicTitle) {
      // just clear the tags
      if (angular.isDefined($ctrl.doc.tags[topicTitle])) {
        $ctrl.doc.tags[topicTitle] = [];
      }
    };

    $ctrl.addNewTopic = function (item) {
      var newTopicId = item;
      KmService.getTagTerms(Array.of(newTopicId))
        .then(function (tagData) {
          _.merge($ctrl.tagSuggestions, tagData);
        });
    };

    $ctrl.handleEditSave = function () {
      if ($ctrl.isEditMode) {
        $ctrl.saveTags();
        $ctrl.isEditMode = false;
      }
      else {
        $ctrl.getSuggestions();
        $ctrl.isEditMode = true;
        $ctrl.docClone = _.cloneDeep($ctrl.doc);

        if (angular.isUndefined($ctrl.docClone.tags.topic)) {
          $ctrl.docClone.tags.topic = [];
        }
      }
    };

    $ctrl.cancelEdit = function () {
      $ctrl.isEditMode = false;
      // Restore the cloned object and undo any changes
      if ($ctrl.docClone) {
        $ctrl.doc = $ctrl.docClone;
      }
    };

    $ctrl.saveTags = function () {
      var partialDoc = {
        id: $ctrl.doc.id,
        tags: _.pickBy($ctrl.doc.tags, function (tagList) {
          return tagList.length > 0;
        }),
      };
      KmService.saveTags(partialDoc);
    };


    $ctrl.triggerTopicSearch = function (topic) {
      var query = 'topic:' + topic;
      //  Caught in FacetedSearch component
      $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', query);
    };

    $ctrl.getSuggestions = function () {
      $ctrl.tagSuggestions = {};
      KmService.getTagTopics()
        .then(function (topicData) {
          $ctrl.tagSuggestions.topic = _.map(topicData, function (val, key) {
            return key;
          });

          if (angular.isDefined($ctrl.doc.tags.topic)) {
            KmService.getTagTerms($ctrl.doc.tags.topic)
              .then(function (tagData) {
                _.forEach(tagData, function (tagsArr, key) {
                  $ctrl.tagSuggestions[key] = _.map(tagsArr, function (val) {
                    return val;
                  });
                });
              });
          }
        });
    };

    $ctrl.onAddUnseenTag = function (tagString) {
      // handle event when user tries to add a string that is not in the current list of options
      // for now, we prevent it (return nothing)
    };

    $ctrl.setSourceAuthorisation = function () {
      $ctrl.hasSourceAuthorisation = Auth.hasSourceAccess($ctrl.doc.source);
    };

    $ctrl.getEditTagTooltipLabel = function () {
      return $ctrl.hasSourceAuthorisation ? '' : 'Unauthorised to perform this action';
    };

    $ctrl.editTagButtonDisabled = function () {
      return !$ctrl.isAuthenticated() || !$ctrl.hasSourceAuthorisation;
    };

    $ctrl.$onInit = function () {
      $ctrl.setSourceAuthorisation();
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('ErrorModalController', ErrorModalController);
  ErrorModalController.$inject = ['curError', 'modalTitle'];

  function ErrorModalController (curError, modalTitle) {
    var vm = this;
    vm.cancel = cancel;

    vm.curError = curError;
    vm.modalTitle = modalTitle || 'Error';

    function cancel () {
      /**
       * @todo make cancel option
       */
    }
  }
}());

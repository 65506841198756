(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('TripleSearchModalController', TripleSearchModalController);
  TripleSearchModalController.$inject = ['$scope', '$uibModalInstance', 'KmService', 'SearchService'];

  function TripleSearchModalController ($scope, $uibModalInstance, KmService, SearchService) {
    var vm = this;
    vm.selectedConcept = null;
    vm.individual = '';
    vm.selectedIndividual = null;
    vm.selectedRelationship = null;
    vm.relationships = {};

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.triggerSearch = function (fromIndividual, toIndividual) {
      var searchQuery = fromIndividual.class + ':"' + fromIndividual.label
       + '" AND ' + toIndividual.class + ':"' + toIndividual.label + '"';
      $scope.$emit('facetedSearchResultsItem:tagSearchTriggered', searchQuery);
      vm.cancel(true);
    };

    function getRelations (element) {
      vm.selectedIndividual = element.suggest ? element.suggest : element.label;
      if (element.type) {
        vm.selectedConcept = _.find(vm.conceptsList, ['url', element.type]).label;
      }
      KmService.getRelationsById(element.id).then(function (d) {
        vm.relationships = {};
        _.forEach(d.nodes, function (ea) {
          ea.class = getClassFromURI(ea.type);
        });
        var nodeMap = _.mapKeys(d.nodes, 'id');
        _.forEach(d.edges, function (e) {
          if (_.isUndefined(vm.relationships[e.title])) {
            vm.relationships[e.title] = [];
          }
          vm.relationships[e.title].push({
            from: nodeMap[e.from],
            to: nodeMap[e.to],
          });
        });
      });
    }

    function getClassFromURI (typeURI) {
      var split = typeURI.split('#'); // first split by hashtag
      if (split.length > 1) {
        return split[split.length - 1];
      }
      split = typeURI.split('/'); // if no hashtag, split by /. for nhb specific cases
      if (split.length > 1) {
        return split[split.length - 1];
      }
      // invalid type URI. could not find class
      return typeURI;
    }

    loadConcepts();

    function loadConcepts () {
      KmService.getConcepts().then(function (response) {
        vm.conceptsList = response;
      });
    }

    vm.canSearchIndividual = function () {
      return vm.selectedConcept !== null;
    };

    vm.canSearchRelationships = function () {
      return vm.selectedIndividual !== null;
    };

    vm.canViewResults = function () {
      return vm.selectedRelationship !== null;
    };

    vm.resetIndividual = function () {
      vm.individual = '';
      vm.selectedIndividual = null;
      vm.selectedRelationship = null;
    };

    vm.getRelationships = function () {
      return _.keys(vm.relationships);
    };

    vm.getAutocomplete = function (text) {
      return SearchService.execGetAutocomplete(text, vm.selectedConcept)
        .then(SearchService.buildAutocomplete);
    };

    $scope.onSelect = function ($model) {
      vm.selectedIndividual = $model;
      getRelations($model);
    };
  }
}());

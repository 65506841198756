(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchFilterDate', {
      bindings: {
        api: '=',
        onDateChange: '&', //  Trigger searchFilter component to update its List
        filterTitle: '=', // title specified by filterComponent
      },
      templateUrl: 'app/components/faceted-search-filter-date/faceted-search-filter-date.component.html',
      controller: facetedSearchFilterDate,
    });

  facetedSearchFilterDate.$inject = ['$timeout'];

  function facetedSearchFilterDate ($timeout) {
    var $ctrl = this;
    $ctrl.dateFilterTitle = 'Modified Date'; //  Display for date filter
    $ctrl.dateData = { uiShowContents: false, start: undefined, end: undefined };
    //  TODO Shift Display name etc to backend
    $ctrl.dateField = 'lastModifiedDate'; //  hardcoded for backend

    /**
     * Toggle display of dropdown
     */
    $ctrl.toggleContent = function () {
      $ctrl.dateData.uiShowContents = !$ctrl.dateData.uiShowContents;
    };

    /**
     *  Triggered when user changes date input
     */
    $ctrl.triggerOptionChange = function (bIsReset) {
      $timeout(function () {
        //  Trigger on next tick, to ensure that Moment is set to undefined before sending to parent

        //  Build Object to be passed to parent
        var dateObject = {
          start: $ctrl.dateData.start,
          end: $ctrl.dateData.end,
          field: $ctrl.dateField,
          uiTitle: $ctrl.dateFilterTitle,
        };

        if (angular.isFunction($ctrl.onDateChange)) {
          $ctrl.onDateChange({
            dateObject: dateObject,
            bIsReset: bIsReset,
          });
        }
      }, 0);
    };

    $ctrl.clearStartDate = function () {
      $ctrl.dateData.start = undefined;
    };

    $ctrl.clearEndDate = function () {
      $ctrl.dateData.end = undefined;
    };

    $ctrl.clearDateByKey = function (dateKey) {
      switch (dateKey) {
        case 'gte':
        case 'from':
          $ctrl.clearStartDate();
          break;

        case 'lte':
        case 'to':
          $ctrl.clearEndDate();
          break;
        default:
          break;
      }
    };

    /**
     * set end/start value from Date object and key
     * @param {String} dateKey
     * @param {Moment} date
     */
    $ctrl.setDateByKey = function (dateKey, date) {
      switch (dateKey) {
        case 'gte':
        case 'from':
          $ctrl.dateData.start = moment.utc(date);
          $ctrl.showContent();
          break;

        case 'lte':
        case 'to':
          $ctrl.dateData.end = moment.utc(date);
          $ctrl.showContent();
          break;
        default:
          break;
      }
    };

    $ctrl.hideContent = function () {
      $ctrl.dateData.uiShowContents = false;
    };

    $ctrl.resetDateRanges = function () {
      $ctrl.dateData.start = undefined;
      $ctrl.dateData.end = undefined;
    };

    $ctrl.showContent = function () {
      $ctrl.dateData.uiShowContents = true;
    };

    $ctrl.isDateField = function (field) {
      return $ctrl.dateField === field;
    };

    $ctrl.showActiveLabel = function () {
      return !!($ctrl.dateData.start || $ctrl.dateData.end);
    };

    $ctrl.api = {
      hideContent: $ctrl.hideContent, //  Collapse contents
      resetDateRanges: $ctrl.resetDateRanges,
      clearDateByKey: $ctrl.clearDateByKey,
      clearEndDate: $ctrl.clearEndDate,
      clearStartDate: $ctrl.clearStartDate,
      triggerOptionChange: $ctrl.triggerOptionChange,
      setDateByKey: $ctrl.setDateByKey,
      isDateField: $ctrl.isDateField,
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('SaveSearchViewModalController', SaveSearchViewModalController);
  SaveSearchViewModalController.$inject = ['SearchService', '$timeout', 'SaveSearchModalService', 'loadSearchCallback'];

  function SaveSearchViewModalController (SearchService, $timeout, SaveSearchModalService, loadSearchCallback) {
    var vm = this;
    vm.cancel = cancel;
    vm.savedSearchData = {};

    //  loadSearchCallback passed from SaveSearchModalService.openView();
    vm.loadSearchCallback = loadSearchCallback;

    vm.title = '';
    vm.errMsg = '';
    vm.editingId = '';
    vm.renameString = '';

    vm.sharingId = '';
    vm.sharingEmail = '';
    vm.isSubmitting = false;

    vm.updateMessage = {
      bIsError: false,
      msg: '',
    };

    $timeout(function () {
      angular.element('#title').focus();
    });

    function cancel () {
      // Auth.cancel();
      SaveSearchModalService.close();
    }

    vm.isSavedSearchDataEmpty = function () {
      return !_.keys(vm.savedSearchData).length;
    };

    vm.editSearch = function (searchId) {
      var searchToChange = vm.savedSearchData[searchId];
      vm.editingId = searchId;
      vm.renameString = searchToChange.name;
    };

    vm.stopEditing = function () {
      vm.editingId = '';
      vm.renameString = '';
    };

    vm.shareSearch = function (searchId) {
      var search = vm.savedSearchData[searchId];

      if (angular.isUndefined(search)) {
        vm.updateMessage.bIsError = true;
        vm.updateMessage.msg = 'Invalid Criteria to share';
        return;
      }

      vm.sharingId = searchId;
    };

    vm.stopSharing = function () {
      vm.sharingId = '';
      vm.updateMessage.bIsError = false;
      vm.updateMessage.msg = '';
      vm.isSubmitting = false;
    };

    vm.shareSearchToEmail = function () {
      vm.updateMessage.bIsError = false;
      vm.updateMessage.msg = '';
      vm.isSubmitting = true;
      SearchService.execShareSavedSearch(vm.sharingId, vm.sharingEmail)
        .then(function (data) {
          vm.updateMessage.bIsError = false;
          vm.updateMessage.msg = 'Share Criteria successfully';
        }).catch(function (error) {
          vm.updateMessage.bIsError = true;
          vm.updateMessage.msg = 'Error share search criteria';
        }).finally(function () {
          vm.isSubmitting = false;
        });
    };

    vm.renameSearch = function (id) {
      var searchToChange = vm.savedSearchData[id];

      if (angular.isUndefined(searchToChange)) {
        vm.updateMessage.bIsError = true;
        vm.updateMessage.msg = 'Invalid Criteria to rename';
        return;
      }

      var oldName = searchToChange.name;

      if (vm.renameString.length <= 0 || vm.renameString === oldName) {
        vm.updateMessage.bIsError = true;
        vm.updateMessage.msg = 'New criteria name contains 0 characters or is the same as current naming';
        return;
      }

      SearchService.execRenameSavedSearch(vm.renameString, searchToChange)
        .then(function (data) {
          vm.savedSearchData[data.id] = data;
          vm.stopEditing();
          vm.updateMessage.bIsError = false;
          vm.updateMessage.msg = 'Search Criteria successfully renamed';
        }).catch(function (error) {
          vm.updateMessage.bIsError = true;
          vm.updateMessage.msg = 'Error renaming search criteria';
        });
    };

    vm.deleteSearch = function (id) {
      var searchToDelete = vm.savedSearchData[id];

      if (angular.isUndefined(searchToDelete)) {
        vm.updateMessage.bIsError = true;
        vm.updateMessage.msg = 'Invalid Criteria to delete';
        return;
      }

      SearchService.execDeleteSavedSearch(searchToDelete)
        .then(function (data) {
          _.unset(vm.savedSearchData, data.id);
          vm.updateMessage.bIsError = false;
          vm.updateMessage.msg = 'Search Criteria successfully deleted';
        }).catch(function (error) {
          vm.updateMessage.bIsError = true;
          vm.updateMessage.msg = 'Error deleting search criteria';
        });
    };

    vm.loadSearch = function (id) {
      var search = vm.savedSearchData[id];

      if (angular.isUndefined(search) || angular.isUndefined(search.request)) {
        vm.updateMessage.bIsError = true;
        vm.updateMessage.msg = 'Invalid Criteria to restore';
        return;
      }
      if (angular.isFunction(vm.loadSearchCallback)) {
        if (vm.loadSearchCallback({
          request: search.request,
        }) === true) {
          //  successfully loaded search
          cancel();
        }
      }
    };

    vm.$onInit = function () {
      SearchService.execGetSavedSearches()
        .then(function (data) {
          vm.savedSearchData = _(data)
            .sortBy(['name', 'id'])
            .mapKeys('id')
            .value();
        })
        .catch(function (error) {
          vm.updateMessage.bIsError = true;
          vm.updateMessage.msg = 'Failed to retrieve list';
        });
    };
  }
}());

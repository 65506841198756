(function () {
  'use strict';

  angular
    .module('isearchApp')
    .config(httpConfig);

  httpConfig.$inject = ['$urlRouterProvider',
    '$httpProvider',
    'httpRequestInterceptorCacheBusterProvider',
    '$urlMatcherFactoryProvider',
    '$locationProvider',
    'IS_AD_AUTH'];

  function httpConfig ($urlRouterProvider,
    $httpProvider,
    httpRequestInterceptorCacheBusterProvider,
    $urlMatcherFactoryProvider,
    $locationProvider,
    IS_AD_AUTH) {
    // Cache everything except rest api requests
    // httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

    // for now, cache everything except urls with keyword `group`
    httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*group.*/], true);

    $urlRouterProvider.otherwise('/dashboard');

    $httpProvider.interceptors.push('errorHandlerInterceptor');
    $httpProvider.interceptors.push('csrfInterceptor');
    $httpProvider.interceptors.push('authInterceptor');
    $httpProvider.interceptors.push('customInterceptor');

    $httpProvider.defaults.withCredentials = true;
    // jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here

    // $locationProvider.html5Mode({ enabled: true, requireBase: false });

    $urlMatcherFactoryProvider.type('boolean', {
      name: 'boolean',
      decode: function (val) {
        return val === true || val === 'true';
      },
      encode: function (val) {
        return val ? 1 : 0;
      },
      equals: function (a, b) {
        return this.is(a) && a === b;
      },
      is: function (val) {
        return [true, false, 0, 1].indexOf(val) >= 0;
      },
      pattern: /bool|true|0|1/,
    });
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('LoginModalService', LoginModalService);

  LoginModalService.$inject = ['$uibModal', '$rootScope', '$state'];

  function LoginModalService ($uibModal, $rootScope, $state) {
    var service = {
      open: open,
      close: close,
    };

    var modalInstance = null;
    var resetModal = function () {
      modalInstance = null;
    };

    var LOGIN_ERROR_TYPES = {
      A_401: '<strong>Failed to sign in!</strong> Please check your credentials and try again.',
      401: '<strong>You are not signed in!</strong> Please Login before viewing this Page',
      500: '<strong>Failed to sign in!</strong> Something is wrong with the Server. Please contact your System Administrator',
      AD_401: '<strong>Failed to sign in!</strong> User not authenticated',
      AD_401_TIMEOUT: '<strong>Session timed out!</strong> Please refresh browser',
    };

    $rootScope.$on('isearchApp.http401AuthError', function () {
      redirectHome();
      open(LOGIN_ERROR_TYPES.A_401);
    });

    $rootScope.$on('isearchApp.http401Error', function () {
      redirectHome();
      open(LOGIN_ERROR_TYPES[401]);
    });

    $rootScope.$on('isearchApp.http500AuthError', function () {
      redirectHome();
      open(LOGIN_ERROR_TYPES[500]);
    });

    $rootScope.$on('isearchApp.http401AuthADError', function () {
      openError('Access denied', LOGIN_ERROR_TYPES.AD_401);
    });
    $rootScope.$on('isearchApp.http401AuthADTimeoutError', function () {
      openError('Access denied', LOGIN_ERROR_TYPES.AD_401_TIMEOUT);
    });

    return service;

    function redirectHome () {
      if ($state.current.name === 'app.dashboard') {
        $state.go('app.dashboard', { query: null }, { notify: false });
      }
      else {
        $state.go('app.dashboard');
      }
    }

    function close () {
      modalInstance.dismiss('cancel');
    }

    function open (errorStr) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/login/login.modal.html',
        controller: 'LoginModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          curError: function () {
            return errorStr;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }

    function openError (modalTitle, errorStr) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/error/error.modal.html',
        controller: 'ErrorModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          curError: function () {
            return errorStr;
          },
          modalTitle: function () {
            return null;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }
  }
}());

(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchFilter', {
      bindings: {
        api: '=',
        searchQuery: '=',
        onFilterUpdate: '&', //  Triggers $ctrl.triggerFilterUpdate (aggregations) in facetedSearchComponent
        onDateRangeUpdate: '&', //  Triggers $ctrl.triggerDateRangeUpdate (rangeParam) in facetedSearchComponent
        onYearRangeUpdate: '&', //  Triggers $ctrl.triggerYearRangeUpdate (rangeParam) in facetedSearchComponent
      },
      templateUrl: 'app/components/faceted-search-filter/faceted-search-filter.component.html',
      controller: facetedSearchFilter,
    });

  facetedSearchFilter.$inject = ['$timeout', 'SearchService'];

  function facetedSearchFilter ($timeout, SearchService) {
    var $ctrl = this;
    $ctrl.filterOptionApis = {}; //  map of child filter option APIs. Indexed by filter option key (ie. docFormat)
    $ctrl.aggregations = {};
    $ctrl.availFilterClasses = [];
    $ctrl.filterConfigs = undefined;

    /**
     * Trigger facetedSearch Component to update its AppliedFilter List
     */
    $ctrl.updateFilterList = function (name, selectedList, bIsReset) {
      // assign selected filters from filter option component
      $ctrl.aggregations[name] = selectedList;
      if (angular.isFunction($ctrl.onFilterUpdate)) {
        $ctrl.onFilterUpdate({
          aggregations: $ctrl.aggregations,
          bIsReset: bIsReset,
        });
      }
    };
    /**
   * convert filterList json to JS values
   * filter list in the form of [{field:, value:}, {} ...]
   * @param {Array} filterList
   */
    $ctrl.updateFilterListFromCriteria = function (filterList) {
      // group by filter type
      $ctrl.resetFilters();
      var groupedList = _(filterList).groupBy('field').value();

      SearchService
        .execGetAggregationByClass($ctrl.searchQuery, _.keys(groupedList), filterList)
        .then(function (data) {
          _.forEach(data, function (val, key) {
            if (angular.isDefined($ctrl.filterOptionApis[key])) {
              $ctrl.filterOptionApis[key].setFilterData(val);
              var selectedList = _.map(groupedList[key], 'value') || [];
              $ctrl.filterOptionApis[key].setSelectedFilters(
                selectedList
              );
              $ctrl.updateFilterList(key, selectedList, true);
            }
          });
        });
    };

    /**
   * convert filterDateList json to JS values
   * {field:xx, gte: xx, lte: xx, to: xx, from: xx,}
   * @param {Array} filterList
   */
    $ctrl.updateDateRangeFromCriteria = function (rangeQueryList) {
    // group by filter type
      $ctrl.resetDateRange();
      _.forEach(rangeQueryList, function (dateObject) {
        if (angular.isDefined(dateObject.field)
          && $ctrl.filterDateApi.isDateField(dateObject.field)) {
          _.forEach(dateObject, function (val, dateKey) {
            if (angular.isDefined(val) && val !== null) {
              $ctrl.filterDateApi.setDateByKey(dateKey, val);
            }
          });
          $ctrl.updateDateRange(dateObject);
        }
      });
    };

    /**
   * convert filterDateList json to JS values
   * {field:xx, gte: xx, lte: xx}
   * @param {Array} filterList
   */
    $ctrl.updateYearRangeFromCriteria = function (rangeQueryList) {
    // group by filter type
      $ctrl.resetYearRange();
      _.forEach(rangeQueryList, function (yearObject) {
        if (angular.isDefined(yearObject.field)
          && $ctrl.filterYearApi.isYearField(yearObject.field)) {
          _.forEach(yearObject, function (val, yearKey) {
            if (angular.isDefined(val) && val !== null) {
              $ctrl.filterYearApi.setYearByKey(yearKey, val);
            }
          });
          $ctrl.updateYearRange(yearObject);
        }
      });
    };

    /**
     * Trigger facetedSearch Component to update its RangeParam List
     */
    $ctrl.updateDateRange = function (dateObject, bIsReset) {
      // var dateRangeParam = $ctrl.getDateRangeParam();
      if (angular.isFunction($ctrl.onDateRangeUpdate)) {
        $ctrl.onDateRangeUpdate({
          dateObject: dateObject,
          bIsReset: bIsReset,
        });
      }
    };
    /**
     * Trigger facetedSearch Component to update its RangeParam List
     */
    $ctrl.updateYearRange = function (yearObject, bIsReset) {
      if (angular.isFunction($ctrl.onYearRangeUpdate)) {
        $ctrl.onYearRangeUpdate({
          yearObject: yearObject,
          bIsReset: bIsReset,
        });
      }
    };

    /**
     * Call API to clear option with specified name and key
     * @param {String} name ie. docFormat
     * @param {String} key  ie. Excel
     */
    $ctrl.uncheckOption = function (name, key) {
      $ctrl.filterOptionApis[name].uncheckOption(key);
      $ctrl.filterOptionApis[name].triggerOptionChange();
    };
    /**
     * Set start/end values for $ctrl.dateRange depending on which keys were removed
     * @param {String} dateKey ie. gte, lte, from, to
     */
    $ctrl.uncheckDate = function (dateKey) {
      $ctrl.filterDateApi.clearDateByKey(dateKey);
      $ctrl.filterDateApi.triggerOptionChange();
    };
    $ctrl.uncheckYear = function (yearKey) {
      $ctrl.filterYearApi.clearYearByKey(yearKey);
      $ctrl.filterYearApi.triggerOptionChange();
    };

    /**
     * Collapse all FilterOption drawers
     */
    $ctrl.resetFilters = function () {
      _.forEach($ctrl.filterOptionApis, function (optionApi) {
        optionApi.hideContent();
        optionApi.resetFilterOptions();
        optionApi.triggerOptionChange(true);
      });
    };

    /**
     * Collapse FilterDate drawer
     */
    $ctrl.resetDateRange = function () {
      $ctrl.filterDateApi.hideContent();
      $ctrl.filterDateApi.resetDateRanges();
      $ctrl.filterDateApi.triggerOptionChange(true);
    };

    /**
     * Collapse FilterDate drawer
     */
    $ctrl.resetYearRange = function () {
      $ctrl.filterYearApi.hideContent();
      $ctrl.filterYearApi.resetYearRanges();
      $ctrl.filterYearApi.triggerOptionChange(true);
    };

    /**
     * Get available autocomplete values from user input
     * @param {String} textVal
     */
    $ctrl.getAutocomplete = function (textVal) {
      return SearchService.execGetAutocomplete(textVal)
        .then(SearchService.buildAutocomplete);
    };

    /**
     * Set Available Filter Classes
     * @param {Object} data
     */
    $ctrl.setAggregations = function (data) {
      const aggregationsList = data.aggregations;

      const aggregationNameList = aggregationsList.filter(function (aggregation) {
        return aggregation.bucket && aggregation.bucket.length > 0;
      }).map(function (agg) {
        return agg.name;
      });

      $ctrl.availFilterClasses = aggregationNameList;
      $timeout(function () { // trigger in next tick
        aggregationsList.forEach(function (aggregation) {
          if (aggregation.bucket && aggregation.bucket.length > 0) {
            if (angular.isDefined($ctrl.filterOptionApis[aggregation.name])) {
              $ctrl.filterOptionApis[aggregation.name].updateFilterData(aggregation.bucket);
            }
          }
        });
      }, 0);
    };

    $ctrl.isFilterClassAvailable = function (strClass) {
      return $ctrl.availFilterClasses.indexOf(strClass) > -1;
    };

    $ctrl.loadFilterOptions = function (conceptList, selectedFilters) {
      // update this with topics must
      SearchService
        .execGetAggregationByClass($ctrl.searchQuery, conceptList)
        .then(function (data) {
          _.forEach(data, function (val, key) {
            if (angular.isDefined($ctrl.filterOptionApis[key])) {
              $ctrl.filterOptionApis[key].setFilterData(val);
            }
          });
        });
    };

    $ctrl.api = {
      uncheckOption: $ctrl.uncheckOption,
      uncheckDate: $ctrl.uncheckDate,
      uncheckYear: $ctrl.uncheckYear,
      resetFilters: $ctrl.resetFilters,
      resetDateRange: $ctrl.resetDateRange,
      resetYearRange: $ctrl.resetYearRange,
      setSearchQuery: $ctrl.setSearchQuery,
      setAggregations: $ctrl.setAggregations,
      updateFilterListFromCriteria: $ctrl.updateFilterListFromCriteria,
      updateDateRangeFromCriteria: $ctrl.updateDateRangeFromCriteria,
      updateYearRangeFromCriteria: $ctrl.updateYearRangeFromCriteria,
    };

    $ctrl.$onInit = function () {
      if (angular.isUndefined($ctrl.filterConfigs)) {
        //  getFilterConfigs() returns a promise. we will use the data in the promise and assign
        //  also remove filters that have have 'displayed' flag off
        SearchService.getFilterConfigs().then(function (data) {
          $ctrl.filterConfigs = _.forEach(data, function (val, key) {
            if (!val.displayed) {
              delete data[key];
            }
          });
          _.forEach($ctrl.filterConfigs, function (val, key) {
            if (angular.isUndefined($ctrl.aggregations[key])) {
              $ctrl.aggregations[key] = [];
            }
          });
        });
      }
    };
  }
}());

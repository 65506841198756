(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchResultsItem', {
      bindings: {
        doc: '=docData', //  DocumentModel
        filterConfigs: '=', // list of available filters
        previewText: '=', // $ctrl.previewText(id, title) from facetedSearchResults
      },
      templateUrl: 'app/components/faceted-search-results-item/faceted-search-results-item.component.html',
      controller: facetedSearchResultsItem,
    });

  facetedSearchResultsItem.$inject = ['$scope', 'Auth'];

  /**
   * Events this component will emit:
   * facetedSearchResultsItem:tagSearchTriggered - Query String
   */
  function facetedSearchResultsItem ($scope, Auth) {
    var $ctrl = this;
    $ctrl.showAttachments = false;
    $ctrl.showTags = false;
    $ctrl.isAuthenticated = function () {
      return Auth.isAuthenticated();
    };

    $ctrl.toggleAttachmentView = function () {
      $ctrl.showAttachments = !$ctrl.showAttachments;
    };

    $ctrl.triggerPreview = function () {
      $ctrl.previewText($ctrl.doc.id, $ctrl.doc.title);
    };

    $ctrl.getDocType = function () {
      if ($ctrl.doc.metadata && $ctrl.doc.metadata.fileExtension) {
        return $ctrl.doc.metadata.fileExtension;
      }
      return null;
    };

    $ctrl.getDocSource = function () {
      if ($ctrl.doc.source) {
        return $ctrl.doc.source;
      }
      return null;
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = ['$localStorage', '$cookies', 'IS_AD_AUTH', 'AUTH_TIMEOUT_BUFFER', '$rootScope'];

  function authInterceptor ($localStorage, $cookies, IS_AD_AUTH, AUTH_TIMEOUT_BUFFER, $rootScope) {
    var service = {
      request: request,
    };

    return service;

    function request (config) {
      if (!config || !config.url) return config;
      /* jshint camelcase: false */
      if (angular.isDefined($cookies.get('CSRF-TOKEN-UI'))) {
        var csrfToken = $cookies.get('CSRF-TOKEN-UI');
        config.headers['X-CSRF-TOKEN'] = csrfToken;
      }

      // console.log(config);
      if (config.method !== 'POST' && config.method !== 'DELETE') return config;

      var user = $localStorage.userModel;

      if (angular.isObject(config.data)) {
        config.data = config.data || {};
        if (user) {
          if (config.url.indexOf('/criteria') === -1) {
            if (config.url.indexOf('/share') === -1) config.data.user = user;
          }
          else if (angular.isDefined(config.data.request)) {
            //  for criteria requests
            config.data.request.user = user;
          }
        }
      }

      /**
       * if AD and nearing timeout, reauthenticate
       */
      if (IS_AD_AUTH) {
        const timeout = new Date(
          new Date(user.expirationTime)
            .setMinutes(new Date(user.expirationTime)
              .getMinutes() - AUTH_TIMEOUT_BUFFER)
        );
        const now = new Date();
        if (now > timeout) {
          // Auth.execADAuth();
          $rootScope.$emit('isearchApp.refreshADUser', config);
        }
      }

      return config;
    }
  }
}());

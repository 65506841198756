(function () {
  angular
    .module('isearchApp')
    .component('facetedSearchFilterYear', {
      bindings: {
        api: '=',
        onYearChange: '&', //  Trigger searchFilter component to update its List
        filterTitle: '=', // title specified by filterComponent
      },
      templateUrl: 'app/components/faceted-search-filter-year/faceted-search-filter-year.component.html',
      controller: facetedSearchFilterYear,
    });

  facetedSearchFilterYear.$inject = ['$timeout'];

  function facetedSearchFilterYear ($timeout) {
    var $ctrl = this;
    $ctrl.yearFilterTitle = 'Year'; //  Display for date filter
    $ctrl.yearData = { uiShowContents: false, start: undefined, end: undefined };
    $ctrl.invalidInput = null;
    $ctrl.errors = {
      INVALID_YEAR: 'Year is invalid!',
      START_HIGHER_THAN_END: 'End Year should be higher than Start Year!',

    };
    // //  TODO Shift Display name etc to backend
    $ctrl.yearField = ['creationPeriod.startYear', 'creationPeriod.endYear']; //  hardcoded for backend

    /**
     * Toggle display of dropdown
     */
    $ctrl.toggleContent = function () {
      $ctrl.yearData.uiShowContents = !$ctrl.yearData.uiShowContents;
    };

    $ctrl.validateInput = function () {
      if ($ctrl.isInvalidYear($ctrl.yearData.start)
      || $ctrl.isInvalidYear($ctrl.yearData.end)
      ) {
        $ctrl.invalidInput = 'INVALID_YEAR';
        return;
      }

      if (!$ctrl.isEmptyYear($ctrl.yearData.start)
        && !$ctrl.isEmptyYear($ctrl.yearData.end)
      ) {
        $ctrl.invalidInput = $ctrl.yearData.start > $ctrl.yearData.end ? 'START_HIGHER_THAN_END' : null;
      }
      else {
        $ctrl.invalidInput = null;
      }
    };

    /**
     *  Triggered when user changes date input
     */
    $ctrl.triggerOptionChange = function (bIsReset) {
      $timeout(function () {
        //  Trigger on next tick, to ensure that Moment is set to undefined before sending to parent
        $ctrl.validateInput();

        if ($ctrl.invalidInput) {
          return;
        }
        //  Build Object to be passed to parent
        var yearObject = {
          start: $ctrl.yearData.start,
          end: $ctrl.yearData.end,
          uiTitle: $ctrl.yearFilterTitle,
        };

        if (angular.isFunction($ctrl.onYearChange)) {
          $ctrl.onYearChange({
            yearObject: yearObject,
            bIsReset: bIsReset,
          });
        }
      });
    };

    $ctrl.clearStartYear = function () {
      $ctrl.yearData.start = undefined;
    };

    $ctrl.clearEndYear = function () {
      $ctrl.yearData.end = undefined;
    };

    $ctrl.clearYearByKey = function (yearObject) {
      switch (yearObject.field) {
        case 'creationPeriod.startYear':
          $ctrl.clearStartYear();
          break;

        case 'creationPeriod.endYear':
          $ctrl.clearEndYear();
          break;
        default:
          break;
      }
    };

    /**
     * set end/start value from Date object and key
     * @param {String} yearKey
     * @param {Number} year
     */
    $ctrl.setYearByKey = function (yearKey, year) {
      switch (yearKey) {
        case 'gte':
          $ctrl.yearData.start = year;
          $ctrl.showContent();
          break;

        case 'lte':
          $ctrl.yearData.end = year;
          $ctrl.showContent();
          break;
        default:
          break;
      }
    };

    $ctrl.hideContent = function () {
      $ctrl.yearData.uiShowContents = false;
    };

    $ctrl.resetYearRanges = function () {
      $ctrl.yearData.start = undefined;
      $ctrl.yearData.end = undefined;
    };

    $ctrl.showContent = function () {
      $ctrl.yearData.uiShowContents = true;
    };

    $ctrl.isYearField = function (field) {
      return $ctrl.yearField.indexOf(field) >= 0;
    };

    $ctrl.isEmptyYear = function (year) {
      const isEmpty = (year === null || year === undefined);
      return isEmpty;
    };

    $ctrl.isInvalidYear = function (year) {
      const isEmpty = $ctrl.isEmptyYear(year);
      const isDecimal = !(year === parseInt(year, 10));
      return !isEmpty && isDecimal;
    };

    $ctrl.showActiveLabel = function () {
      return $ctrl.invalidInput === null
      && (!$ctrl.isEmptyYear($ctrl.yearData.start)
      || !$ctrl.isEmptyYear($ctrl.yearData.end));
    };

    $ctrl.api = {
      hideContent: $ctrl.hideContent, //  Collapse contents
      resetYearRanges: $ctrl.resetYearRanges,
      clearYearByKey: $ctrl.clearYearByKey,
      clearEndYear: $ctrl.clearEndYear,
      clearStartYear: $ctrl.clearStartYear,
      triggerOptionChange: $ctrl.triggerOptionChange,
      setYearByKey: $ctrl.setYearByKey,
      isYearField: $ctrl.isYearField,
    };
  }
}());

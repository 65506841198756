(function () {
  angular
    .module('isearchApp')
    .factory('WidgetService', WidgetService);

  WidgetService.$inject = ['WIDGET_SETTINGS'];

  function WidgetService (WIDGET_SETTINGS) {
    var isWidgetEnabled = function (widgetName) {
      if (widgetName && WIDGET_SETTINGS && WIDGET_SETTINGS[widgetName] && WIDGET_SETTINGS[widgetName].enabled !== null) {
        return WIDGET_SETTINGS[widgetName].enabled;
      }
      return false;
    };
    var getWidgetProps = function (widgetName) {
      if (!(widgetName && WIDGET_SETTINGS[widgetName] && WIDGET_SETTINGS[widgetName].props)) {
        return null;
      }
      return WIDGET_SETTINGS[widgetName].props;
    };
    var setWidgetProp = function (widget, prop, value) {
      widget[prop] = value;
    };
    var widgetService = {
      isWidgetEnabled: isWidgetEnabled,
      getWidgetProps: getWidgetProps,
      setWidgetProp: setWidgetProp,
    };
    return widgetService;
  }
}());

(function () {
  angular
    .module('isearchApp')
    .factory('DocumentModel', DocumentFactory);

  function DocumentFactory () {
    function DocumentModel (data) {
      if (angular.isUndefined(data)) {
        return undefined;
      }

      var doc = {};

      doc = {
        id: angular.isDefined(data.id) ? data.id : null,
        title: angular.isDefined(data.title) ? data.title : null,
        source: angular.isDefined(data.source) ? data.source : null,
        path: angular.isDefined(data.path) ? data.path : null,
        content: angular.isDefined(data.content) ? data.content : null,
        appType: angular.isDefined(data.appType) ? data.appType : null,
        createdDate: angular.isDefined(data.createdDate) ? data.createdDate : null,
        lastModifiedDate: angular.isDefined(data.lastModifiedDate) ? data.lastModifiedDate : null,
        version: angular.isDefined(data.version) ? data.version : 0,
        latest: angular.isDefined(data.latest) ? data.latest : false,
        roles: angular.isDefined(data.roles) ? data.roles : [],
        metadata: angular.isDefined(data.metadata) ? data.metadata : {},
        docFormat: angular.isDefined(data.docFormat) ? data.docFormat : null,
        sensitive: angular.isDefined(data.sensitive) ? data.sensitive : false,
        visualization: angular.isDefined(data.visualization) ? data.visualization : null,
        blurb: angular.isDefined(data.blurb) ? data.blurb : null,
        mailto: angular.isDefined(data.mailto) ? data.mailto : null,
        alternativeTitle: angular.isDefined(data.alternativeTitle) ? data.alternativeTitle : null,
        highlight: angular.isDefined(data.highlight) ? data.highlight : null,
        tags: angular.isDefined(data.tags) ? data.tags : {},
        annotations: angular.isDefined(data.annotations) ? data.annotations : '',
        subsite: angular.isDefined(data.annotations) ? data.annotations : '',
        featured: angular.isDefined(data.featured) ? data.featured : null,
        relationChain: angular.isDefined(data.relationChain) ? data.relationChain : null,
      };

      return doc;
    }
    /**
     * Static methods , assigned to class
     * Instance ('this') is not available in static context
     */

    /**
      * Build array of DocumentModel from JSON array response
      */
    DocumentModel.buildFromArray = function (responseData, size) {
      var documents = [];
      var count = 0;
      _.forEach(responseData, function (data) {
        if (count >= size) {
          return;
        }
        var doc = new DocumentModel(data);
        if (angular.isDefined(doc)) {
          documents.push(doc);
        }
        count += 1;
      });

      return documents;
    };

    /*     /**
     * Map Concepts in document
     * @param {DocumentModel} doc
     */
    /* DocumentModel.mapConcepts = function (doc) {

    }; */

    return DocumentModel;
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig ($stateProvider) {
    $stateProvider.state('app.dashboard', {
      // start with a query field, can add more optional parameters later
      // https://benfoster.io/blog/ui-router-optional-parameters
      url: '/dashboard?query&criteria',
      data: {
        authorities: [],
      },
      views: {
        'content@': {
          templateUrl: 'app/pages/dashboard/dashboard.html',
          controller: 'DashboardController',
          controllerAs: 'vm',
        },
      },
    })
      .state('app.criteria', {
        // start with a query field, can add more optional parameters later
        // https://benfoster.io/blog/ui-router-optional-parameters
        url: '/criteria?criteria',
        data: {
          authorities: [],
        },
        views: {
          'content@': {
            templateUrl: 'app/pages/dashboard/dashboard.html',
            controller: 'DashboardController',
            controllerAs: 'vm',
          },
        },
      });
  }
}());

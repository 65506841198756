(function () {
  angular
    .module('isearchApp')
    .component('searchNlpResult', {
      bindings: {
        api: '=',
        // onTriggerSuggestedSearch: '&',
      },
      templateUrl: 'app/components/search-nlp-result/search-nlp-result.component.html',
      controller: searchNlpResult,
    });

  searchNlpResult.$inject = ['SearchService'];

  function searchNlpResult (SearchService) {
    var $ctrl = this;

    $ctrl.answers = [];

    $ctrl.execGetNlpResult = function (query) {
      if (!query.length) {
        return;
      }

      SearchService.execNLPSearch(query).then(function (data) {
        $ctrl.answers = [];

        if (angular.isUndefined(data)) {
          return;
        }

        $ctrl.answers = data;
      });
    };

    $ctrl.api = {
      execGetNlpResult: $ctrl.execGetNlpResult,
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('PreviewTextModalService', PreviewTextModalService);

  PreviewTextModalService.$inject = ['$uibModal'];

  function PreviewTextModalService ($uibModal) {
    var service = {
      open: open,
      close: close,
    };

    var modalInstance = null;
    var resetModal = function () {
      modalInstance = null;
    };

    return service;

    function close () {
      modalInstance.dismiss('cancel');
    }

    //  opened from FacetedSearchResultsComponent $ctrl.previewText
    function open (id, title) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/preview-text/preview-text.modal.html',
        controller: 'PreviewTextModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          id: function () {
            return id;
          },
          title: function () {
            return title;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }
  }
}());

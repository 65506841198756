(function () {
  'use strict';

  angular
    .module('isearchApp')
    .factory('SaveSearchModalService', SaveSearchModalService);

  SaveSearchModalService.$inject = ['$uibModal'];

  function SaveSearchModalService ($uibModal) {
    var service = {
      open: open,
      openView: openView,
      close: close,
    };

    var modalInstance = null;
    var resetModal = function () {
      modalInstance = null;
    };

    return service;

    function close () {
      modalInstance.dismiss('cancel');
    }

    //  opened from SearchBarComponent $ctrl.saveSearch
    function open (saveSearchCallback) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/save-search/save-search.modal.html',
        controller: 'SaveSearchModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          saveSearchCallback: function () {
            return saveSearchCallback;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }

    //  opened from SearchBarComponent $ctrl.getSavedSearches
    function openView (loadSearchCallback) {
      if (modalInstance !== null) return;
      modalInstance = $uibModal.open({
        templateUrl: 'app/modals/save-search/save-search-view.modal.html',
        controller: 'SaveSearchViewModalController',
        controllerAs: 'vm',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          loadSearchCallback: function () {
            return loadSearchCallback;
          },
        },
      });

      modalInstance.result.then(
        resetModal,
        resetModal
      );
    }
  }
}());

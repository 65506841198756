(function () {
  'use strict';

  angular
    .module('isearchApp')
    .controller('PreviewTextModalController', PreviewTextModalController);
  PreviewTextModalController.$inject = ['PreviewTextModalService', 'SearchService', 'id', 'title'];

  function PreviewTextModalController (PreviewTextModalService, SearchService, id, title) {
    var vm = this;
    vm.title = title;
    vm.textContent = '';
    vm.cancel = cancel;

    function getTextContent () {
      SearchService.execGetTextContent(id)
        .then(function (data) {
          vm.textContent = data;
        });
    }

    function cancel () {
      PreviewTextModalService.close();
    }

    getTextContent();
  }
}());
